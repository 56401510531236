<template>
  <VueFinalModal name="payment" :focus-trap="true" v-bind="$attrs" classes="modal-container" content-class="modal-content" @before-open="onBeforeOpen" @closed="onClose">
    <div v-if="isVideo || isVideo2" class="overlay-video">
      <div class="overlay-video__wrapper">
        <iframe
          width="446"
          height="670"
          v-if="isVideo"
          src="https://www.youtube.com/embed/X4_Uwz1fgaM?controls=0&autoplay=1&loop=1"
          title="Как пополнить кошелёк в Telegram! P2P-Crypto"
          frameborder="0"
          allow="autoplay; encrypted-media; web-share"
          referrerpolicy="strict-origin-when-cross-origin"
          allowfullscreen
          v-click-outside="
            () => {
              isVideo = false;
              isVideo2 = false;
            }
          "
        ></iframe>
        <iframe
          width="446"
          height="670"
          v-else
          src="https://www.youtube.com/embed/2586seyYFDE?controls=0&autoplay=1&loop=1"
          title="Как оплатить методом P2P-СНГ?"
          frameborder="0"
          allow="autoplay; encrypted-media; web-share"
          referrerpolicy="strict-origin-when-cross-origin"
          allowfullscreen
          v-click-outside="
            () => {
              isVideo = false;
              isVideo2 = false;
            }
          "
        ></iframe>
        <VButton color="light" type="button" size="xs" class="close">
          <FontIcon icon="close" size="20" />
        </VButton>
      </div>
    </div>
    <div class="modal_payment" :class="{ 'modal_payment--long': activeTab === 'history' }">
      <div class="modal_payment__header">
        <div class="modal_payment__header-nav">
          <button
            type="button"
            class="modal_payment__header-nav-item"
            :class="{
              'modal_payment__header-nav-item--active': activeTab === 'deposit',
            }"
            @click="selectTab('deposit')"
          >
            <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg" class="modal_payment__header-nav-item-icon">
              <defs>
                <linearGradient id="gradient" x1="0.15" y1="0.85" x2="0.85" y2="0.15">
                  <stop offset="0%" stop-color="#69A4FF" />
                  <stop offset="94%" stop-color="#7449E6" />
                </linearGradient>
              </defs>
              <path
                opacity="0.3"
                d="M10.5007 20.9167C16.2536 20.9167 20.9173 16.253 20.9173 10.5C20.9173 4.74707 16.2536 0.083374 10.5007 0.083374C4.74768 0.083374 0.0839844 4.74707 0.0839844 10.5C0.0839844 16.253 4.74768 20.9167 10.5007 20.9167Z"
                fill="#A0ACD3"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M9.45833 9.45833V5.29167C9.45833 4.71637 9.9247 4.25 10.5 4.25C11.0753 4.25 11.5417 4.71637 11.5417 5.29167V9.45833H15.7083C16.2836 9.45833 16.75 9.9247 16.75 10.5C16.75 11.0753 16.2836 11.5417 15.7083 11.5417H11.5417V15.7083C11.5417 16.2836 11.0753 16.75 10.5 16.75C9.9247 16.75 9.45833 16.2836 9.45833 15.7083V11.5417H5.29167C4.71637 11.5417 4.25 11.0753 4.25 10.5C4.25 9.9247 4.71637 9.45833 5.29167 9.45833H9.45833Z"
                fill="#A0ACD3"
              />
            </svg>

            <span>{{ $t("modal.payment.tab1") }}</span>
          </button>
          <button
            type="button"
            class="modal_payment__header-nav-item"
            :class="{
              'modal_payment__header-nav-item--active': activeTab === 'withdraw',
            }"
            @click="selectTab('withdraw')"
          >
            <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg" class="modal_payment__header-nav-item-icon">
              <defs>
                <linearGradient id="gradient" x1="0.15" y1="0.85" x2="0.85" y2="0.15">
                  <stop offset="0%" stop-color="#69A4FF" />
                  <stop offset="94%" stop-color="#7449E6" />
                </linearGradient>
              </defs>
              <path
                opacity="0.3"
                d="M10.5007 20.9167C16.2536 20.9167 20.9173 16.253 20.9173 10.5C20.9173 4.74707 16.2536 0.083374 10.5007 0.083374C4.74768 0.083374 0.0839844 4.74707 0.0839844 10.5C0.0839844 16.253 4.74768 20.9167 10.5007 20.9167Z"
                fill="#A0ACD3"
              />
              <path
                d="M15.625 9.625H5.375C4.89175 9.625 4.5 10.0168 4.5 10.5C4.5 10.9832 4.89175 11.375 5.375 11.375H15.625C16.1082 11.375 16.5 10.9832 16.5 10.5C16.5 10.0168 16.1082 9.625 15.625 9.625Z"
                fill="#A0ACD3"
              />
            </svg>
            <span>{{ $t("modal.payment.tab2") }}</span>
          </button>
          <button
            type="button"
            class="modal_payment__header-nav-item"
            :class="{
              'modal_payment__header-nav-item--active': activeTab === 'history',
            }"
            @click="selectTab('history')"
          >
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="modal_payment__header-nav-item-icon">
              <defs>
                <linearGradient id="gradient" x1="0.15" y1="0.85" x2="0.85" y2="0.15">
                  <stop offset="0%" stop-color="#69A4FF" />
                  <stop offset="94%" stop-color="#7449E6" />
                </linearGradient>
              </defs>
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M10.9621 7.5H11.0466C11.3035 7.5 11.5186 7.69465 11.5441 7.95025L11.9991 12.5L15.2472 14.356C15.4029 14.4451 15.4991 14.6107 15.4991 14.7902V15C15.4991 15.2109 15.3281 15.3819 15.1172 15.3819C15.0832 15.3819 15.0495 15.3774 15.0167 15.3684L10.3978 14.1087C10.1664 14.0456 10.0124 13.8271 10.0308 13.588L10.4636 7.96165C10.4836 7.70115 10.7008 7.5 10.9621 7.5Z"
                fill="#A0ACD3"
              />
              <path
                opacity="0.3"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M7.3898 2.83497C8.65217 2.29745 10.0413 2.00002 11.5 2.00002C17.299 2.00002 22 6.70103 22 12.5C22 18.299 17.299 23 11.5 23C5.70101 23 1 18.299 1 12.5C1 11.5151 1.13559 10.562 1.38913 9.65807L3.31481 10.1982C3.10672 10.9401 3 11.7119 3 12.5C3 17.1944 6.80558 21 11.5 21C16.1944 21 20 17.1944 20 12.5C20 7.8056 16.1944 4.00002 11.5 4.00002C10.5488 4.00002 9.62236 4.15594 8.74872 4.45448L9.93948 5.87357C10.0088 5.95619 10.0496 6.059 10.0557 6.16668C10.0713 6.44238 9.86045 6.67854 9.58475 6.69417L4.71778 6.96997C4.66931 6.97272 4.6207 6.96839 4.57348 6.95713C4.30487 6.89306 4.13907 6.62337 4.20314 6.35476L5.33164 1.62363C5.35654 1.51922 5.41438 1.42561 5.4966 1.35661C5.70814 1.17911 6.02352 1.2067 6.20102 1.41824L7.3898 2.83497Z"
                fill="#A0ACD3"
              />
            </svg>
            <span>{{ $t("modal.payment.tab3") }}</span>
          </button>
        </div>
        <button @click="$vfm.hide('payment')" class="modal_payment__header-close">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M0.313541 15.6231C-0.104514 15.2025 -0.104514 14.5233 0.313541 14.1028L14.0394 0.376922C14.3029 0.0692215 14.7166 -0.0648086 15.1105 0.0299373C15.5044 0.124683 15.8119 0.432214 15.9067 0.826092C16.0014 1.21997 15.8674 1.63372 15.5597 1.89722L1.83384 15.6231C1.41329 16.0411 0.734091 16.0411 0.313541 15.6231Z"
              fill="#B9C0D3"
            />
            <path
              d="M0.313541 0.376651C0.734091 -0.0414033 1.41329 -0.0414033 1.83384 0.376651L15.5597 14.1025C15.8674 14.366 16.0014 14.7798 15.9067 15.1736C15.8119 15.5675 15.5044 15.875 15.1105 15.9698C14.7166 16.0645 14.3029 15.9305 14.0394 15.6228L0.313541 1.89695C-0.104514 1.4764 -0.104514 0.797201 0.313541 0.376651Z"
              fill="#B9C0D3"
            />
          </svg>
        </button>
      </div>
      <div class="modal_payment__body">
        <div class="modal_payment__body-left" :class="{ hm: activeTab === 'history' }">
          <div class="modal_payment__body-label">{{ $t("modal.payment.balance") }}:</div>
          <div class="modal_payment__body-balance">RUB ({{ $auth.user.money }})</div>
          <div class="modal_payment__body-label">
            {{ $t("modal.payment.methods") }}
          </div>
          <button type="button" @click="showMethods = !showMethods" class="modal_payment__body-method modal_payment__body-method--select">
            <img v-if="selectedMethod.icon" :src="`/images/pay/${selectedMethod.icon.split('.')[0]}.png`" class="modal_payment__body-method-icon" alt="" />
            {{ selectedMethod.label || $t("modal.payment.select_methods") }}
            <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg" class="ml-auto">
              <path
                d="M5.43431 6.56569C5.74673 6.8781 6.25327 6.8781 6.56569 6.56569L11.6569 1.47452C11.9693 1.1621 11.9693 0.655566 11.6569 0.343146C11.3444 0.0307267 10.8379 0.0307266 10.5255 0.343146L6 4.86863L1.47452 0.343146C1.1621 0.0307262 0.655565 0.0307262 0.343146 0.343146C0.0307266 0.655565 0.0307266 1.1621 0.343146 1.47452L5.43431 6.56569ZM5.2 5L5.2 6L6.8 6L6.8 5L5.2 5Z"
                fill="#CCD2E8"
              />
            </svg>
          </button>
          <template v-if="showMethods">
            <button
              type="button"
              @click="selectMethod(method.name)"
              class="modal_payment__body-method"
              :class="{
                'modal_payment__body-method--active':
                  (['card', 'qiwi', 'sbp'].includes(getIconByName(method.name)) && getIconByName(method.name) === getIconByName(form.system)) ||
                  form.system === method.name ||
                  (method.category === 'Криптовалюты' && (selectedMethod && selectedMethod.category) === 'Криптовалюты') ||
                  (method.category === 'Telegram Wallet' && (selectedMethod && selectedMethod.category) === 'Telegram Wallet'),
              }"
              v-for="(method, idx) in methods"
              :key="`method-${idx}`"
            >
              <img :src="`/images/pay/${method.icon && method.icon.split('.')[0]}.png`" class="modal_payment__body-method-icon" :class="{ sberpay: method.name.startsWith('sber') }" alt="" />
              {{ method.label }}
              <div class="modal_payment__body-method-badge" v-if="method.meta?.badge">
                <img src="@/assets/img/top.svg?url" alt="" />
                {{ method.meta?.badge }}
              </div>
            </button>
          </template>
        </div>
        <div class="modal_payment__body-right">
          <div v-if="isCrypto">
            <div class="modal_payment__body-label">
              {{ $t("modal.payment.select_currency") }}
            </div>
            <div class="modal_payment__body-operators">
              <button
                type="button"
                class="modal_payment__body-operators-item"
                v-for="(card, idx) in methodsList.filter((x) => x.category === 'Криптовалюты')"
                :class="{
                  'modal_payment__body-operators-item--active': card.name === form.system,
                }"
                :key="`o-${idx}`"
                @click="selectMethod(card.name)"
              >
                <img :src="`/images/pay/${card.icon && card.icon.split('.')[0]}.png`" class="modal_payment__body-operators-item-icon" alt="" />
                <span class="modal_payment__body-operators-item-text">{{ card.label }}</span>
              </button>
            </div>
            <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" class="modal_payment__body-qr modal_payment__body-qr--loader" v-if="!wallets[selectedMethod.method]">
              <defs>
                <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%">
                  <stop offset="0%" stop-color="#7449E6" />
                  <stop offset="100%" stop-color="#69A4FF" />
                </linearGradient>
              </defs>
              <circle cx="25" cy="25" r="20" fill="none" stroke="#F4F2FB" stroke-width="5"></circle>
              <circle class="path" cx="25" cy="25" r="20" fill="none" stroke="url(#gradient)" stroke-width="5"></circle>
            </svg>
            <img :src="qrcode" alt="QR code" class="modal_payment__body-qr" v-else />
            <div class="modal_payment__body-label">
              {{ $t("modal.payment.address") }}
            </div>
            <div class="mb15 relative">
              <input class="modal_payment__body-input input-address" :value="wallets[selectedMethod.method] && wallets[selectedMethod.method].wallet.address" readonly />
              <button class="btn-pay-copy" @click="copy(wallets[selectedMethod.method] && wallets[selectedMethod.method].wallet.address)">
                <img src="/icons/copy.svg" alt="" />
              </button>
            </div>
            <div class="modal_payment__body-rates mb15">
              {{ $t("modal.payment.fee") }}:
              <span class="modal_payment__body-rates-black">{{ selectedMethod.commission }}%</span>
              <span class="modal_payment__body-rates-slash">&nbsp;/</span><span class="modal_payment__body-rates-clear"></span> {{ $t("modal.payment.limit_dep") }}:

              <span class="modal_payment__body-rates-black"
                >{{ $t("modal.payment.at") }} {{ parseFloat(selectedMethod.min) }}
                {{ wallets[selectedMethod.method] && wallets[selectedMethod.method].wallet.currency && wallets[selectedMethod.method].wallet.currency.replace("ETH", "ETHERC") }}</span
              >
            </div>
            <div class="modal_payment__body-alert-qiwi modal_payment__body-alert-qiwi-withdraw">
              <img src="/images/pay/warning.png" alt="" />
              {{ $t("modal.payment.annot_crypto") }}
            </div>
            <div class="modal_payment__body-currency mb15">
              1
              {{ wallets[selectedMethod.method] && wallets[selectedMethod.method].wallet.currency && wallets[selectedMethod.method].wallet.currency.replace("ETH", "ETHERC") }}
              ≈
              {{ wallets[selectedMethod.method] && wallets[selectedMethod.method].course.buy }}
              {{ $t("modal.payment.rub") }}
            </div>
            <div class="modal_payment__body-alert-default mb15">
              {{ $t("modal.payment.annot1") }}
              {{ wallets[selectedMethod.method] && wallets[selectedMethod.method].wallet.currency && wallets[selectedMethod.method].wallet.currency.replace("ETH", "ETHERC") }}
              {{ wallets[selectedMethod.method] && wallets[selectedMethod.method].wallet.network }}.
              {{ $t("modal.payment.annot11") }}
            </div>

            <button @click="showCalculator = !showCalculator" type="button" class="modal_payment__body-submit mb15">
              {{ $t("modal.payment.calc") }}
            </button>

            <div v-show="showCalculator" class="modal_payment__body-grid2">
              <div>
                <div class="modal_payment__body-label">
                  {{ $t("modal.payment.sum_dep") }}
                </div>
                <div class="modal_payment__body-input-wrapper">
                  <div class="modal_payment__body-input-prefix modal_payment__body-input-prefix--rub">₽</div>
                  <input class="modal_payment__body-input modal_payment__body-input--icon" v-model="cryptoForm.amount" required placeholder="0.00" />
                </div>
              </div>
              <div>
                <div class="modal_payment__body-label">
                  {{ $t("modal.payment.sum_pay") }}
                </div>
                <div class="modal_payment__body-input-wrapper">
                  <input class="modal_payment__body-input" :value="cryptoAmount" placeholder="0.00" readonly />
                </div>
              </div>
            </div>
          </div>
          <div v-else-if="isUnconfirmed" class="unconfirmed">
            <img src="@/assets/svg/alert-alt.svg?url" class="unconfirmed__img" alt="" />
            <div class="unconfirmed__title">{{ $t("modal.payment.unc_title") }}</div>
            <div class="unconfirmed__text">
              {{ $t("modal.payment.unc_text_1") }}<button type="button" @click="showProfile" class="unconfirmed__link">{{ $t("modal.payment.unc_text_2") }}</button>{{ $t("modal.payment.unc_text_3") }}
            </div>
            <VButton class="unconfirmed__btn" @click="showProfile">{{ $t("modal.payment.unc_submit") }}</VButton>
          </div>
          <div v-else-if="isTelegramWallet">
            <div class="modal_payment__body-label">
              {{ $t("modal.payment.select_currency") }}
            </div>
            <div class="modal_payment__body-operators">
              <button
                type="button"
                class="modal_payment__body-operators-item"
                v-for="(card, idx) in methodsList.filter((x) => x.category === 'Telegram Wallet' && x.name !== 'wallet')"
                :class="{
                  'modal_payment__body-operators-item--active': card.name === form.system,
                }"
                :key="`o-${idx}`"
                @click="selectMethod(card.name)"
              >
                <img :src="`/images/pay/${card.icon && card.icon.split('.')[0]}.png`" class="modal_payment__body-operators-item-icon" alt="" />
                <span class="modal_payment__body-operators-item-text">{{ card.label }}</span>
              </button>
            </div>
            <div class="modal_payment__body-faq">
              <div class="left" @click="isVideo = true">
                <img src="@/assets/img/video.png" class="img" alt="" />
              </div>
              <div>
                <div class="title">{{ $t("modal.payment.tg_title") }}</div>
                <div class="text">{{ $t("modal.payment.tg_text") }}</div>
              </div>
            </div>
            <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" class="modal_payment__body-qr modal_payment__body-qr--loader" v-if="!wallets[selectedMethod.method]">
              <defs>
                <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%">
                  <stop offset="0%" stop-color="#7449E6" />
                  <stop offset="100%" stop-color="#69A4FF" />
                </linearGradient>
              </defs>
              <circle cx="25" cy="25" r="20" fill="none" stroke="#F4F2FB" stroke-width="5"></circle>
              <circle class="path" cx="25" cy="25" r="20" fill="none" stroke="url(#gradient)" stroke-width="5"></circle>
            </svg>
            <img :src="qrcode" alt="QR code" class="modal_payment__body-qr" v-else />
            <div class="modal_payment__body-label">
              {{ $t("modal.payment.address") }}
            </div>
            <div class="mb15 relative">
              <input class="modal_payment__body-input input-address" :value="wallets[selectedMethod.method] && wallets[selectedMethod.method].wallet.address" readonly />
              <button class="btn-pay-copy" @click="copy(wallets[selectedMethod.method] && wallets[selectedMethod.method].wallet.address)">
                <img src="/icons/copy.svg" alt="" />
              </button>
            </div>
            <div class="modal_payment__body-rates mb15">
              {{ $t("modal.payment.fee") }}:
              <span class="modal_payment__body-rates-black">{{ selectedMethod.commission }}%</span>
              <span class="modal_payment__body-rates-slash">&nbsp;/</span><span class="modal_payment__body-rates-clear"></span> {{ $t("modal.payment.limit_dep") }}:

              <span class="modal_payment__body-rates-black"
                >{{ $t("modal.payment.at") }} {{ parseFloat(selectedMethod.min) }}
                {{ wallets[selectedMethod.method] && wallets[selectedMethod.method].wallet.currency && wallets[selectedMethod.method].wallet.currency.replace("ETH", "ETHERC") }}</span
              >
            </div>
            <div class="modal_payment__body-alert-qiwi modal_payment__body-alert-qiwi-withdraw">
              <img src="/images/pay/warning.png" alt="" />
              {{ $t("modal.payment.annot_crypto") }}
            </div>
            <div class="modal_payment__body-currency mb15">
              1
              {{ wallets[selectedMethod.method] && wallets[selectedMethod.method].wallet.currency && wallets[selectedMethod.method].wallet.currency.replace("ETH", "ETHERC") }}
              ≈
              {{ wallets[selectedMethod.method] && wallets[selectedMethod.method].course.buy }}
              {{ $t("modal.payment.rub") }}
            </div>
            <div class="modal_payment__body-alert-default mb15">
              {{ $t("modal.payment.annot1") }}
              {{ wallets[selectedMethod.method] && wallets[selectedMethod.method].wallet.currency && wallets[selectedMethod.method].wallet.currency.replace("ETH", "ETHERC") }}
              {{ wallets[selectedMethod.method] && wallets[selectedMethod.method].wallet.network }}.
              {{ $t("modal.payment.annot11") }}
            </div>

            <button @click="showCalculator = !showCalculator" type="button" class="modal_payment__body-submit mb15">
              {{ $t("modal.payment.calc") }}
            </button>

            <div v-show="showCalculator" class="modal_payment__body-grid2">
              <div>
                <div class="modal_payment__body-label">
                  {{ $t("modal.payment.sum_dep") }}
                </div>
                <div class="modal_payment__body-input-wrapper">
                  <div class="modal_payment__body-input-prefix modal_payment__body-input-prefix--rub">₽</div>
                  <input class="modal_payment__body-input modal_payment__body-input--icon" v-model="cryptoForm.amount" required placeholder="0.00" />
                </div>
              </div>
              <div>
                <div class="modal_payment__body-label">
                  {{ $t("modal.payment.sum_pay") }}
                </div>
                <div class="modal_payment__body-input-wrapper">
                  <input class="modal_payment__body-input" :value="cryptoAmount" placeholder="0.00" readonly />
                </div>
              </div>
            </div>
          </div>

          <form @submit.prevent="onSubmit" v-else>
            <template v-if="activeTab === 'deposit'">
              <div class="modal_payment__body-loader" v-if="waiting || (loading && form.system === 'qiwi4') || form.system === 'skins'">
                <PaymentLoader v-if="isCascadeCard || isCascadeSbp" class="modal_payment__body-qr--spinner" />
                <svg v-else xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" class="modal_payment__body-qr modal_payment__body-qr--loader">
                  <defs>
                    <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%">
                      <stop offset="0%" stop-color="#7449E6" />
                      <stop offset="100%" stop-color="#69A4FF" />
                    </linearGradient>
                  </defs>
                  <circle cx="25" cy="25" r="20" fill="none" stroke="#F4F2FB" stroke-width="5"></circle>
                  <circle class="path" cx="25" cy="25" r="20" fill="none" stroke="url(#gradient)" stroke-width="5"></circle>
                </svg>
                <div class="modal_payment__waiting" :class="{ cascade: isCascadeCard || isCascadeSbp }">
                  {{ isCascadeCard || isCascadeSbp ? $t("modal.payment.gateway_casacde") : waiting || $t("modal.payment.gateway") }}
                </div>
              </div>
              <template v-else>
                <div
                  v-if="(form.system.includes('card') || form.system.includes('sbp')) && activeTab === 'deposit' && !isCascadeCard && !isCascadeSbp"
                  class="modal_payment__body-alert-qiwi modal_payment__body-alert-qiwi-withdraw"
                >
                  <img src="/images/pay/warning.png" alt="" />
                  {{ $t("modal.payment.methods_alert") }}
                </div>
                <template v-if="(form.system.includes('card') || form.system.includes('sbp') || form.system.includes('qiwi')) && activeTab === 'deposit' && !isCascadeCard && !isCascadeSbp">
                  <div class="modal_payment__body-label">
                    {{ $t("modal.payment.operator") }}
                  </div>
                  <div class="modal_payment__body-operators">
                    <button
                      type="button"
                      class="modal_payment__body-operators-item"
                      v-for="(card, idx) in methodsList.filter((x) =>
                        form.system.includes('qiwi')
                          ? x.name.includes('qiwi')
                          : form.system.includes('card')
                          ? x.name.includes('card')
                          : form.system.includes('sbp')
                          ? x.name.includes('sbp')
                          : x.category === 'Криптовалюты'
                      )"
                      :class="{
                        'modal_payment__body-operators-item--active': card.name === form.system,
                      }"
                      :key="`o-${idx}`"
                      @click="selectMethod(card.name)"
                    >
                      <img :src="`/images/pay/${form.system.includes('qiwi') ? 'qiwi' : 'card'}.png`" class="modal_payment__body-operators-item-icon" alt="" />
                      <span class="modal_payment__body-operators-item-text">{{ card.label }}</span>
                    </button>
                  </div>
                </template>
                <template v-if="['qiwi6', 'qiwi'].includes(form.system)">
                  <div class="modal_payment__body-label">
                    {{ $t("modal.payment.phone") }}
                  </div>
                  <input v-mask="depositMask" class="modal_payment__body-input" v-model="form.qiwi_account" type="text" name="phone" required placeholder="7XXXXXXXXXX" />
                  <div class="modal_payment__body-alert-qiwi">
                    <img src="/images/pay/like.png" alt="" />
                    {{ $t("modal.payment.annot_qiwi") }}
                  </div>
                </template>
                <template v-if="['fkwallet', 'yoomoney', 'card2', 'sbp'].includes(form.system)">
                  <div class="modal_payment__body-label">
                    {{ $t("modal.payment.email") }}
                  </div>
                  <input class="modal_payment__body-input mb15" v-model="form.email" type="email" name="email" required placeholder="admin@trix.win" />
                </template>
                <div v-if="form.system === 'sng_p2p1'" class="modal_payment__body-faq">
                  <div class="left" @click="isVideo2 = true">
                    <img src="@/assets/img/video2.png" class="img" alt="" />
                  </div>
                  <div>
                    <div class="title">{{ $t("modal.payment.sng_title") }}</div>
                    <div class="text">{{ $t("modal.payment.sng_text") }}</div>
                  </div>
                </div>
                <div class="modal_payment__body-label">
                  {{ $t("modal.payment.sum_target") }}
                </div>
                <div class="modal_payment__body-input-wrapper">
                  <div class="modal_payment__body-input-prefix modal_payment__body-input-prefix--rub">{{ currencySign }}</div>
                  <input
                    class="modal_payment__body-input modal_payment__body-input--icon"
                    v-model="form.amount"
                    :class="{ 'modal_payment__body-input-prefix--3sign': uzRate }"
                    placeholder="0.00"
                    type="number"
                    step="0.01"
                    @input="onChangeAmount"
                    :min="parseFloat(selectedMethod.min) || 0"
                    :max="parseFloat(selectedMethod.max) || 9999999"
                    required
                  />
                  <div
                    v-tippy="{
                      content: $t('modal.payment.tooltip'),
                      allowHTML: true,
                      arrow: true,
                      placement: 'left',
                    }"
                    v-if="activeTab === 'deposit'"
                    class="modal_payment__body-input-suffix modal_payment__body-input-suffix--bonus"
                  >
                    + {{ $t("modal.payment.bonus") }}
                  </div>
                </div>

                <div class="modal_payment__body-amounts">
                  <button
                    type="button"
                    class="modal_payment__body-amounts-item"
                    :class="{
                      'modal_payment__body-amounts-item--active': form.amount === amount && minAmount <= amount,
                    }"
                    v-for="(amount, idx) of providers[selectedMethod.name] || minimals[parseFloat(selectedMethod.min) || 0] || amounts"
                    :key="`amount-${idx}`"
                    @click="onSelectAmount(amount)"
                    :disabled="minAmount && minAmount > amount"
                  >
                    {{ amount >= 1_000_000 ? `${amount / 1_000_000}kk` : amount >= 30000 ? `${amount / 1000}k` : amount }} {{ amount >= 30000 ? "" : currencySign }}
                  </button>
                </div>
                <template v-if="activeTab === 'deposit' && selectedMethod?.name === 'uzs_1'">
                  <div class="mt15">
                    <div class="modal_payment__body-label">
                      {{ $t("modal.payment.sum_total_income") }}
                      <span class="modal_payment__body-label_suffix">1 ₽ ≈ {{ uzRate }} UZS</span>
                    </div>
                    <div class="modal_payment__body-input-wrapper">
                      <div class="modal_payment__body-input-prefix modal_payment__body-input-prefix--rub">₽</div>
                      <input class="modal_payment__body-input modal_payment__body-input--icon" v-model="uzAmount" @input="onChangeCalc" placeholder="0.00" />
                    </div>
                  </div>
                </template>
                <button type="submit" class="modal_payment__body-submit">
                  {{ $t("modal.payment.process_dep") }}
                </button>
                <div v-if="activeTab === 'deposit'" class="modal_payment__body-rates">
                  {{ $t("modal.payment.fee") }}:
                  <span class="modal_payment__body-rates-black">{{ selectedMethod.commission }}%</span>
                  <span class="modal_payment__body-rates-slash">&nbsp;/</span><span class="modal_payment__body-rates-clear"></span> {{ $t("modal.payment.limit_dep") }}:
                  <span v-if="parseFloat(selectedMethod.max)" class="modal_payment__body-rates-black"
                    >{{ parseFloat(selectedMethod.min).toFixed(2) }} {{ currencySign }} - {{ parseFloat(selectedMethod.max).toFixed(2) }} {{ currencySign }}</span
                  >
                  <span class="modal_payment__body-rates-black" v-else>{{ $t("modal.payment.at") }} {{ parseFloat(selectedMethod.min).toFixed(2) }} ₽</span>
                </div>
              </template>
            </template>
            <template v-else-if="activeTab === 'withdraw'">
              <template v-if="$auth.user.code_required">
                <div class="modal_payment__body-label">
                  {{ $t("modal.payment.wallet") }}
                </div>
                <div class="modal_payment__body-input-wrapper modal_payment__dropdown" :class="{ mb15: selectedMethod.name === 'sbp' }">
                  <div class="modal_payment__body-input-prefix modal_payment__body-input-prefix--big">
                    <img v-if="selectedMethod.icon" :src="`/images/pay/${selectedMethod.icon.split('.')[0]}.png`" class="modal_payment__body-operators-item-icon" alt="" />
                  </div>
                  <input
                    v-mask="withdrawMask"
                    :key="`input-${withdrawIndex}`"
                    :name="selectedMethod && selectedMethod.name === 'qiwi' ? 'phone' : 'wallet'"
                    class="modal_payment__body-input modal_payment__body-input--big-icon"
                    type="text"
                    v-model="withdrawForm.purse"
                    required
                    placeholder=""
                    autocomplete="off"
                    ref="withdrawPurse"
                    @focus="walletFocused = false"
                  />
                  <div class="modal_payment__body-input-suffix">
                    <button type="button" class="modal_payment__dots" @click.stop="walletFocused = !walletFocused">
                      <svg width="20" height="20" viewBox="0 0 104 32">
                        <path
                          d="M15.719 29.75c7.974 0 14.438-6.464 14.438-14.438s-6.464-14.438-14.438-14.438v0c-7.974 0-14.438 6.464-14.438 14.438s6.464 14.438 14.438 14.438v0zM50.875 29.75c7.974 0 14.438-6.464 14.438-14.438s-6.464-14.438-14.438-14.438v0c-7.974 0-14.438 6.464-14.438 14.438s6.464 14.438 14.438 14.438v0zM86.063 29.75c7.974 0 14.438-6.464 14.438-14.438s-6.464-14.438-14.438-14.438v0c-7.974 0-14.438 6.464-14.438 14.438s6.464 14.438 14.438 14.438v0z"
                        ></path>
                      </svg>
                    </button>
                  </div>
                  <div
                    v-click-outside="
                      () => {
                        walletFocused = false;
                      }
                    "
                    class="modal_payment__dropdown-body"
                    v-show="walletFocused"
                  >
                    <button
                      v-if="(selectedMethod && !userWallets.filter((x) => x.provider === selectedMethod.name).length) || !filteredWallets.length"
                      type="button"
                      @click="walletFocused = false"
                      class="modal_payment__dropdown-item modal_payment__dropdown-item--center"
                    >
                      {{ $t("modal.payment.layout") }}
                    </button>
                    <button v-for="(walletx, idx) in filteredWallets" :key="`w-${idx}-${walletx.purse}`" type="button" @click="onSelectWallet(walletx.purse)" class="modal_payment__dropdown-item">
                      {{ walletx.purse }}
                    </button>
                  </div>
                </div>
                <VSelect v-if="selectedMethod?.name === 'sbp'" class="mb15" placeholder="Выберите банк" v-model="withdrawForm.bank" :options="bankOptions" required />
                <div class="modal_payment__body-alert-qiwi modal_payment__body-alert-qiwi-withdraw">
                  <img src="/images/pay/warning.png" alt="" />
                  {{ $t("modal.payment.annot_wallet") }}
                </div>
                <div class="modal_payment__body-grid2">
                  <div>
                    <div class="modal_payment__body-label">
                      {{ $t("modal.payment.sum_withdraw") }}
                    </div>
                    <div class="modal_payment__body-input-wrapper">
                      <div class="modal_payment__body-input-prefix modal_payment__body-input-prefix--rub">₽</div>
                      <input
                        class="modal_payment__body-input modal_payment__body-input--icon"
                        v-model="withdrawForm.amount"
                        required
                        placeholder="0.00"
                        step="0.01"
                        type="number"
                        :min="parseFloat(selectedMethod.min) || 0"
                        :max="parseFloat(selectedMethod.max) || 9999999"
                        @input="onChangeAmountWithdraw"
                      />
                    </div>
                  </div>
                  <div>
                    <div class="modal_payment__body-label">
                      {{ $t("modal.payment.sum_payout") }}
                    </div>
                    <div class="modal_payment__body-input-wrapper">
                      <div class="modal_payment__body-input-prefix modal_payment__body-input-prefix--rub">₽</div>
                      <input class="modal_payment__body-input modal_payment__body-input--icon" :value="amountToWithdraw" placeholder="0.00" readonly />
                    </div>
                  </div>
                </div>
                <template v-if="selectedMethod?.name === 'card_uzs'">
                  <div class="mb15">
                    <div class="modal_payment__body-label">
                      {{ $t("modal.payment.sum_total_income") }}
                      <span class="modal_payment__body-label_suffix">1 ₽ ≈ {{ uzRateWithdraw }} UZS</span>
                    </div>
                    <div class="modal_payment__body-input-wrapper">
                      <div class="modal_payment__body-input-prefix modal_payment__body-input-prefix--rub">UZS</div>
                      <input
                        class="modal_payment__body-input modal_payment__body-input--icon modal_payment__body-input-prefix--3sign"
                        v-model="uzAmountWithdraw"
                        @input="onChangeCalcWithdraw"
                        placeholder="0.00"
                      />
                    </div>
                  </div>
                </template>
                <div>
                  <div class="modal_payment__body-label">{{ $t("modal.payment.pin") }}</div>
                  <div class="modal_payment__body-input-wrapper">
                    <input class="modal_payment__body-input" :type="isVisiblePin ? 'text' : 'password'" v-model.trim="withdrawForm.pin" placeholder="****" type="text" />
                    <button type="button" class="modal_payment__body-input--prefix" @click="isVisiblePin = !isVisiblePin">
                      <FontIcon icon="show" />
                    </button>
                  </div>
                </div>
                <button type="submit" class="modal_payment__body-submit mb15">
                  {{ $t("modal.payment.create_withdraw") }}
                </button>
                <div class="modal_payment__body-alert-green mb15">
                  <svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg" class="modal_payment__body-alert-green-icon">
                    <rect width="41" height="41" rx="6" fill="#67C67A" />
                    <path
                      d="M20.9092 13.0264C20.7686 13.0703 20.6602 13.1406 20.5547 13.2549C20.4521 13.3604 15.6738 20.4355 15.5596 20.6465C15.5215 20.7197 15.5039 20.8105 15.5039 20.9688C15.5039 21.2236 15.5625 21.3555 15.7441 21.5312C15.9697 21.751 15.917 21.7451 17.5635 21.7539L19.0342 21.7627L19.0195 21.8711C19.0107 21.9297 18.8789 23.166 18.7266 24.6162C18.4365 27.376 18.4365 27.373 18.5479 27.5869C18.6035 27.6895 18.791 27.8623 18.917 27.9297C18.9902 27.9678 19.0811 27.9854 19.2393 27.9854C19.4941 27.9854 19.6289 27.9268 19.7988 27.7451C19.9219 27.6162 25.3828 19.8203 25.4297 19.709C25.4443 19.668 25.4648 19.5479 25.4736 19.4424C25.4883 19.2021 25.4092 18.9971 25.2393 18.8418C25.0049 18.6309 25.1045 18.6426 23.1211 18.625L21.3311 18.6104L21.6182 16.208C21.791 14.7549 21.8965 13.7588 21.8877 13.6885C21.8496 13.4277 21.6885 13.2021 21.4512 13.082C21.3252 13.0176 21.0293 12.9854 20.9092 13.0264Z"
                      fill="white"
                    />
                  </svg>
                  <div>
                    <div class="modal_payment__body-alert-green-title">
                      {{ $t("modal.payment.fast_title") }}
                    </div>
                    <div>{{ $t("modal.payment.fast_text") }}</div>
                  </div>
                </div>

                <div v-if="selectedMethod.name === 'qiwi'" class="modal_payment__body-alert-red mb15">
                  {{ $t("modal.payment.annot_qiwi_payout") }}
                </div>
                <div class="modal_payment__body-alert-red">
                  {{ $t("modal.payment.annot_sum") }}
                </div>
                <div class="modal_payment__body-rates">
                  {{ $t("modal.payment.fee") }}:
                  <span class="modal_payment__body-rates-black"
                    >{{ selectedMethod.commission }}%{{ selectedMethod.meta && selectedMethod.meta.additional_commission ? ` + ${selectedMethod.meta.additional_commission} ₽` : "" }}</span
                  >
                  <span class="modal_payment__body-rates-slash">&nbsp;/</span><span class="modal_payment__body-rates-clear"></span> {{ $t("modal.payment.limit_withdraw") }}:
                  <span v-if="parseFloat(selectedMethod.max)" class="modal_payment__body-rates-black"
                    >{{ parseFloat(selectedMethod.min).toFixed(2) }} ₽ - {{ parseFloat(selectedMethod.max).toFixed(2) }} ₽</span
                  >
                  <span class="modal_payment__body-rates-black" v-else>{{ $t("modal.payment.at") }} {{ parseFloat(selectedMethod.min).toFixed(2) }} ₽</span>
                </div>
              </template>
              <div v-else class="payment-text-gray pin-text">
                {{ $t("modal.payment.code1") }}<br /><br />
                {{ $t("modal.payment.code2") }}
                <ModalPincodeForm class="payment-pin" />
              </div>
            </template>
            <template v-else>
              <button type="button" class="modal_payment__body-submit mb15 modal_payment__body-find" @click="$vfm.show('payment_find')">
                {{ $t("modal.payment.nodep") }}
              </button>
              <div class="modal_payment__body-tabs">
                <button
                  type="button"
                  @click="selectHistoryTab('deposit')"
                  class="modal_payment__body-tabs-item"
                  :class="{
                    'modal_payment__body-tabs-item--active': activeTabHistory === 'deposit',
                  }"
                >
                  {{ $t("modal.payment.tab_deps") }}
                </button>
                <button
                  type="button"
                  @click="selectHistoryTab('withdraw')"
                  class="modal_payment__body-tabs-item"
                  :class="{
                    'modal_payment__body-tabs-item--active': activeTabHistory === 'withdraw',
                  }"
                >
                  {{ $t("modal.payment.tab_withdraws") }}
                </button>
              </div>
              <div class="modal_payment__body-table">
                <table>
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>{{ $t("modal.payment.t_amount") }}</th>
                      <th>{{ $t("modal.payment.t_method") }}</th>
                      <th v-if="activeTabHistory === 'withdraw'">
                        {{ $t("modal.payment.t_wallet") }}
                      </th>
                      <th>{{ $t("modal.payment.t_date") }}</th>
                      <th>{{ $t("modal.payment.t_status") }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <template v-for="item in history" :key="`row-${item.id}`">
                      <tr
                        @click="openRow(item.id)"
                        :class="{
                          hovered: (item.requisites && (item.requisites.qiwi_wallet || item.requisites.card)) || activeTabHistory === 'withdraw',
                        }"
                      >
                        <td>
                          <div>
                            <svg
                              v-if="(item.meta && item.meta.deposit_points) || (item.requisites && (item.requisites.qiwi_wallet || item.requisites.card)) || activeTabHistory === 'withdraw'"
                              xmlns="http://www.w3.org/2000/svg"
                              version="1.1"
                              xmlns:xlink="http://www.w3.org/1999/xlink"
                              xmlns:svgjs="http://svgjs.com/svgjs"
                              width="30"
                              height="30"
                              x="0"
                              y="0"
                              viewBox="0 0 488.85 488.85"
                              style="enable-background: new 0 0 512 512"
                              xml:space="preserve"
                              class="modal-payment__eye"
                            >
                              <g>
                                <g>
                                  <path
                                    d="M244.425,98.725c-93.4,0-178.1,51.1-240.6,134.1c-5.1,6.8-5.1,16.3,0,23.1c62.5,83.1,147.2,134.2,240.6,134.2
		s178.1-51.1,240.6-134.1c5.1-6.8,5.1-16.3,0-23.1C422.525,149.825,337.825,98.725,244.425,98.725z M251.125,347.025
		c-62,3.9-113.2-47.2-109.3-109.3c3.2-51.2,44.7-92.7,95.9-95.9c62-3.9,113.2,47.2,109.3,109.3
		C343.725,302.225,302.225,343.725,251.125,347.025z M248.025,299.625c-33.4,2.1-61-25.4-58.8-58.8c1.7-27.6,24.1-49.9,51.7-51.7
		c33.4-2.1,61,25.4,58.8,58.8C297.925,275.625,275.525,297.925,248.025,299.625z"
                                    fill="#000000"
                                    data-original="#000000"
                                    class=""
                                  ></path>
                                </g>
                                <g></g>
                                <g></g>
                                <g></g>
                                <g></g>
                                <g></g>
                                <g></g>
                                <g></g>
                                <g></g>
                                <g></g>
                                <g></g>
                                <g></g>
                                <g></g>
                                <g></g>
                                <g></g>
                                <g></g>
                              </g>
                            </svg>
                            #{{ item.id }}
                          </div>
                        </td>
                        <td>
                          <div>{{ item.amount }} ₽</div>
                        </td>
                        <td>
                          <div>
                            <img :class="{ sberpay: item.system === 'sber_pay' }" :src="`/images/pay/${getIconByName(item.system)}.png`" alt="" />
                          </div>
                        </td>
                        <td v-if="activeTabHistory === 'withdraw'">
                          <div>
                            <div class="table-wallet">{{ item.purse }}</div>
                          </div>
                        </td>
                        <td>
                          <div>
                            {{ getDate(item.created_at) }}
                          </div>
                        </td>
                        <td>
                          <div
                            :class="{
                              red: (activeTabHistory === 'deposit' && [2, 6].includes(item.status)) || (activeTabHistory === 'withdraw' && [2, 6, 7].includes(item.status)),
                              green: (activeTabHistory === 'deposit' && item.status === 1) || (activeTabHistory === 'withdraw' && [1, 5].includes(item.status)),
                              yellow: (activeTabHistory === 'deposit' && item.status === 0) || (activeTabHistory === 'withdraw' && [0].includes(item.status)),
                            }"
                          >
                            <button
                              v-if="activeTabHistory === 'withdraw' && [0, 4, 8].includes(item.status)"
                              :disabled="![0, 8].includes(item.status)"
                              class="btn-cancel"
                              @click="onCancelWithdraw(item.id)"
                            >
                              {{ $t("modal.payment.cancel") }}
                            </button>
                            <span v-else-if="activeTabHistory === 'withdraw'">{{ withdrawStatuses[item.status] }}</span>
                            <span v-else>{{ depositStatuses[item.status] }}</span>
                          </div>
                        </td>
                      </tr>
                      <tr
                        v-if="
                          ((item.meta && item.meta.deposit_points) || (item.requisites && (item.requisites.qiwi_wallet || item.requisites.card))) &&
                          activeRowId === item.id &&
                          activeTabHistory === 'deposit'
                        "
                      >
                        <td colspan="100%">
                          <div class="flex-col">
                            <template v-if="item.requisites.qiwi_wallet">
                              <span class="text-a7b0ca"
                                ><b class="text-ffffff">{{ $t("modal.payment.pay_number") }}:</b> {{ item.requisites.qiwi_wallet.number }}</span
                              >
                              <span class="text-a7b0ca"
                                ><b class="text-ffffff">{{ $t("modal.payment.pay_amount") }}:</b> {{ item.requisites.qiwi_wallet.amount }} ₽</span
                              >
                              <span class="text-a7b0ca"
                                ><b class="text-ffffff">{{ $t("modal.payment.pay_comment") }}:</b> {{ item.requisites.qiwi_wallet.comment }}</span
                              >
                            </template>
                            <template v-else-if="item.requisites.card">
                              <span class="text-a7b0ca"
                                ><b class="text-ffffff">{{ $t("modal.payment.pay_number") }}:</b> {{ item.requisites.card.number }}</span
                              >
                              <span class="text-a7b0ca"
                                ><b class="text-ffffff">{{ $t("modal.payment.pay_amount") }}:</b> {{ item.requisites.card.amount }} ₽</span
                              >
                              <span class="text-a7b0ca"
                                ><b class="text-ffffff">{{ $t("modal.payment.pay_bank") }}:</b> {{ item.requisites.card.bank }}</span
                              >
                            </template>
                            <span v-if="item.meta && item.meta.deposit_points" class="text-a7b0ca"
                              ><b class="text-ffffff">{{ $t("modal.payment.pay_points") }}:</b> {{ item.meta.deposit_points }}</span
                            >
                          </div>
                        </td>
                      </tr>
                      <tr v-else-if="activeRowId === item.id && activeTabHistory === 'withdraw'">
                        <td colspan="100%">
                          <div class="flex-col">
                            <span class="text-a7b0ca"
                              ><b class="text-ffffff">{{ $t("modal.payment.reciept_trans") }}:</b> {{ item.total }} ₽</span
                            >
                            <span class="text-a7b0ca"
                              ><b class="text-ffffff">{{ $t("modal.payment.reciept_get") }}:</b> {{ item.amount }} ₽</span
                            >
                            <span class="text-a7b0ca"
                              ><b class="text-ffffff">{{ $t("modal.payment.reciept_date") }}:</b> {{ getDate(item.created_at) }}</span
                            >
                            <span class="text-a7b0ca"
                              ><b class="text-ffffff">{{ $t("modal.payment.reciept_wallet") }}:</b> {{ item.purse }}</span
                            >
                            <span class="text-a7b0ca" v-if="item.meta.reason"
                              ><b class="text-ffffff">{{ $t("modal.payment.reciept_reason") }}:</b> {{ item.meta.reason }}</span
                            >
                          </div>
                        </td>
                      </tr>
                    </template>
                  </tbody>
                </table>
              </div>
              <div v-if="meta.last_page > 1" class="modal_payment__body-pagination">
                <button @click="updatePage(-1)" type="button" class="modal_payment__body-pagination-nav">
                  <svg width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M0.95956 7.29289C0.569036 7.68342 0.569036 8.31658 0.95956 8.70711L7.32352 15.0711C7.71404 15.4616 8.34721 15.4616 8.73773 15.0711C9.12826 14.6805 9.12826 14.0474 8.73773 13.6569L3.08088 8L8.73774 2.34315C9.12826 1.95262 9.12826 1.31946 8.73774 0.928933C8.34721 0.538408 7.71405 0.538408 7.32352 0.928933L0.95956 7.29289ZM2.5 7L1.66667 7L1.66667 9L2.5 9L2.5 7Z"
                      fill="#BAC2D9"
                    />
                  </svg>
                </button>
                <div class="modal_payment__body-pagination-center">
                  <div class="modal_payment__body-pagination-current">
                    {{ meta.current_page }}
                  </div>
                  <div class="modal_payment__body-pagination-total">{{ $t("modal.payment.of") }} {{ meta.last_page }}</div>
                </div>
                <button @click="updatePage(1)" type="button" class="modal_payment__body-pagination-nav">
                  <svg width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M9.04044 8.70711C9.43097 8.31658 9.43097 7.68342 9.04044 7.29289L2.67648 0.928931C2.28596 0.538407 1.65279 0.538407 1.26227 0.928931C0.871743 1.31946 0.871743 1.95262 1.26227 2.34314L6.91912 8L1.26227 13.6569C0.871741 14.0474 0.871741 14.6805 1.26227 15.0711C1.65279 15.4616 2.28595 15.4616 2.67648 15.0711L9.04044 8.70711ZM7.5 9L8.33333 9L8.33333 7L7.5 7L7.5 9Z"
                      fill="#BAC2D9"
                    />
                  </svg>
                </button>
              </div>
            </template>
          </form>
        </div>
      </div>
    </div>
    <LazyModalQiwi v-model="modals.qiwi" />
    <LazyModalCredentials v-model="modals.credentials" />
    <LazyModalDepositFind v-model="modals.deposit_find" />
    <LazyModalPaymentAmount v-model="modals.deposit_amount" />
  </VueFinalModal>
</template>

<script>
import { VueFinalModal, $vfm } from "vue-final-modal";
import { directive } from "vue-tippy";
import { directive as outside } from "click-outside-vue3";
import copier from "clipboard-copy";

import utils from "@/effects/useEffects";
import { phone } from "phone";
import { maska } from "maska";
import { useRouter, useRoute } from "vue-router";
import qr from "qrcode";
import VButton from "../VButton.vue";
import { useVisitorData } from "@fingerprintjs/fingerprintjs-pro-vue-v3";
import { getFingerprint as mixGetFingerprint } from "~~/utils";
import { findLastIndex } from "lodash-es";

export default {
  components: { VueFinalModal },
  directives: {
    mask: maska,
    tippy: directive,
    clickOutside: outside,
  },
  setup(params, refs) {
    const qrcode = ref("");

    function setWallet() {
      const wallet = wallets.value[selectedMethod.value.method].wallet.address;
      if (!wallet) return;

      qr.toDataURL(wallet, (_err, url) => {
        qrcode.value = url;
      });
    }
    const { t } = useI18n();
    const depositStatuses = {
      0: t("modal.payment.waiting"),
      1: t("modal.payment.success"),
      2: t("modal.payment.canceled"),
      6: t("modal.payment.canceled"),
    };
    const withdrawStatuses = {
      0: t("modal.payment.waiting"),
      1: t("modal.payment.success"),
      2: t("modal.payment.canceled"),
      3: t("modal.payment.processing"),
      4: t("modal.payment.queue"),
      5: t("modal.payment.completed"),
      6: t("modal.payment.canceled"),
      7: t("modal.payment.canceled"),
      8: t("modal.payment.moderation"),
    };
    const activeTab = ref("");
    const withdrawPurse = ref(null);
    const withdrawIndex = ref(0);
    const showCalculator = ref(false);
    const walletFocused = ref(false);
    const waiting = ref(false);
    const isVisiblePin = ref(false);
    const isVideo = ref(false);
    const isVideo2 = ref(false);
    const activeTabHistory = ref("withdraw");
    const form = ref({
      amount: "",
      system: "",
      qiwi_account: "",
      email: "",
      pin: "",
    });

    function onSelectWallet(val) {
      withdrawForm.value.purse = val;
      walletFocused.value = false;
      setTimeout(() => {
        withdrawIndex.value++;
      }, 60);
    }

    const bin = {
      tinkoff: [220070, 437772, 437773, 437783, 437784, 470127, 513990, 518901, 521324, 524468, 528041, 538994, 548387, 551960, 553420, 553691, 555323, 555442],
      sberbank: [
        220100,
        220220,
        375122,
        375123,
        375124,
        400680,
        402333,
        417398,
        417638,
        423078,
        427400,
        427401,
        427402,
        427403,
        427404,
        427406,
        427407,
        427408,
        427409,
        427411,
        427412,
        427413,
        427414,
        427415,
        427416,
        427417,
        427418,
        427420,
        427421,
        427422,
        427423,
        427424,
        427425,
        427426,
        427427,
        427428,
        427429,
        427430,
        427431,
        427432,
        427433,
        427434,
        427435,
        427436,
        427437,
        427438,
        427439,
        427440,
        427441,
        427442,
        427443,
        427444,
        427445,
        427446,
        427447,
        427448,
        427449,
        427450,
        427451,
        427452,
        427453,
        427454,
        427455,
        427456,
        427457,
        427458,
        427459,
        427460,
        427461,
        427462,
        427463,
        427464,
        427465,
        427466,
        427467,
        427468,
        427469,
        427470,
        427471,
        427472,
        427473,
        427474,
        427475,
        427476,
        427477,
        427491,
        427499,
        427600,
        427601,
        427602,
        427603,
        427604,
        427605,
        427606,
        427607,
        427608,
        427609,
        427610,
        427611,
        427612,
        427613,
        427614,
        427615,
        427616,
        427617,
        427618,
        427619,
        427620,
        427621,
        427622,
        427623,
        427624,
        427625,
        427626,
        427627,
        427628,
        427629,
        427630,
        427631,
        427632,
        427633,
        427634,
        427635,
        427636,
        427637,
        427638,
        427639,
        427640,
        427641,
        427642,
        427643,
        427644,
        427645,
        427646,
        427647,
        427648,
        427649,
        427650,
        427651,
        427652,
        427653,
        427654,
        427655,
        427656,
        427657,
        427658,
        427659,
        427660,
        427661,
        427662,
        427663,
        427664,
        427665,
        427666,
        427667,
        427668,
        427669,
        427670,
        427671,
        427672,
        427673,
        427674,
        427675,
        427676,
        427677,
        427678,
        427679,
        427680,
        427681,
        427682,
        427683,
        427684,
        427685,
        427686,
        427687,
        427688,
        427689,
        427690,
        427692,
        427693,
        427694,
        427695,
        427696,
        427697,
        427699,
        427900,
        427901,
        427902,
        427903,
        427904,
        427905,
        427906,
        427907,
        427908,
        427909,
        427910,
        427911,
        427912,
        427913,
        427914,
        427915,
        427916,
        427917,
        427918,
        427920,
        427921,
        427922,
        427923,
        427924,
        427925,
        427926,
        427927,
        427928,
        427929,
        427930,
        427931,
        427932,
        427933,
        427935,
        427936,
        427937,
        427938,
        427939,
        427940,
        427941,
        427942,
        427943,
        427944,
        427945,
        427946,
        427948,
        427949,
        427950,
        427951,
        427952,
        427953,
        427954,
        427955,
        427956,
        427957,
        427958,
        427959,
        427960,
        427961,
        427962,
        427963,
        427964,
        427965,
        427966,
        427967,
        427968,
        427969,
        427970,
        427971,
        427972,
        427973,
        427974,
        427975,
        427976,
        427977,
        427978,
        427980,
        427981,
        427982,
        427983,
        427984,
        427985,
        427986,
        427987,
        427988,
        427989,
        427990,
        427991,
        427992,
        427993,
        427994,
        427995,
        427998,
        427999,
        466790,
        467455,
        476997,
        478483,
        479580,
        480110,
        480111,
        480112,
        480113,
        480114,
        481776,
        481779,
        481781,
        483983,
        485463,
        519808,
        521400,
        522860,
        527404,
        527576,
        530829,
        531310,
        533205,
        533208,
        533669,
        536961,
        538150,
        543763,
        545152,
        546901,
        546902,
        546903,
        546904,
        546905,
        546906,
        546907,
        546908,
        546909,
        546910,
        546911,
        546912,
        546913,
        546916,
        546917,
        546918,
        546920,
        546922,
        546925,
        546926,
        546927,
        546928,
        546929,
        546930,
        546931,
        546932,
        546933,
        546935,
        546936,
        546937,
        546938,
        546939,
        546940,
        546941,
        546942,
        546943,
        546944,
        546945,
        546946,
        546947,
        546948,
        546949,
        546950,
        546951,
        546952,
        546953,
        546954,
        546955,
        546956,
        546959,
        546960,
        546961,
        546962,
        546963,
        546964,
        546966,
        546967,
        546968,
        546969,
        546970,
        546972,
        546974,
        546975,
        546976,
        546977,
        546998,
        546999,
        547198,
        547901,
        547902,
        547905,
        547906,
        547907,
        547910,
        547911,
        547912,
        547913,
        547920,
        547922,
        547925,
        547926,
        547927,
        547928,
        547930,
        547931,
        547932,
        547935,
        547936,
        547938,
        547940,
        547942,
        547943,
        547944,
        547945,
        547947,
        547948,
        547949,
        547950,
        547951,
        547952,
        547953,
        547954,
        547955,
        547956,
        547959,
        547960,
        547961,
        547962,
        547966,
        547969,
        547972,
        547976,
        547998,
        547999,
        548401,
        548402,
        548403,
        548405,
        548406,
        548407,
        548410,
        548411,
        548412,
        548413,
        548416,
        548420,
        548422,
        548425,
        548426,
        548427,
        548428,
        548430,
        548431,
        548432,
        548435,
        548436,
        548438,
        548440,
        548442,
        548443,
        548444,
        548445,
        548447,
        548448,
        548449,
        548450,
        548451,
        548452,
        548454,
        548455,
        548456,
        548459,
        548460,
        548461,
        548462,
        548463,
        548464,
        548466,
        548468,
        548469,
        548470,
        548472,
        548476,
        548477,
        548498,
        548499,
        557000,
        605461,
        639002,
        676195,
        676196,
        676280,
        677128,
        964300,
        964301,
        964302,
        964303,
        964304,
        964305,
        964307,
        964308,
        964309,
        964310,
        964311,
        964312,
        964313,
        964314,
        964315,
        964316,
        964317,
        964318,
        964319,
        964320,
        964321,
        964322,
        964323,
        964324,
        964325,
        964326,
        964327,
        964328,
        964329,
        964330,
        964331,
        964332,
        964333,
        964334,
        964335,
        964336,
        964337,
        964338,
        964339,
        964340,
        964341,
        964342,
        964343,
        964344,
        964345,
        964346,
        964347,
        964348,
        964349,
        964350,
        964351,
        964352,
        964353,
        964354,
        964355,
        964356,
        964357,
        964358,
        964359,
        964360,
        964361,
        964362,
        964363,
        964364,
        964365,
        964366,
        964367,
        964368,
        964369,
        964370,
        964371,
        964372,
        964373,
        964374,
        964375,
        964376,
        964378,
        964379,
        964380,
        964381,
        964382,
        964383,
        964384,
        964385,
        964386,
        964387,
        964388,
        964389,
        964390,
        964391,
        964392,
        964393,
        964394,
        964395,
        964396,
        964397,
        964398,
        964399,
      ],
    };
    function validateBin(card) {
      const bins = Object.values(bin).flat();
      return bins.findIndex((x) => card.startsWith(x)) !== -1;
    }
    const providers = {
      card12: [309, 502, 1008, 2009, 5009, 10008],
      card5: [505, 1001, 2002, 5005, 8008, 10001],
      card8: [101, 303, 505, 1001, 5005, 10001],
      sbp3: [101, 303, 505, 1001, 5005, 10001],
      sbp1: [505, 1001, 2002, 5005, 8008, 10001],
      sbp5: [1501, 2002, 3003, 5005, 8008, 10001],
      card13: [1501, 2002, 3003, 5005, 8008, 10001],
      card10: [800, 1000, 2000, 5000, 8000, 10000],
      card_cascade: [1000, 2000, 3000, 5000, 8000, 10000],
      card_cascade_trust: [350, 500, 750, 1000, 5000, 10000],
      uzs_1: [30_000, 75_000, 145_000, 300_000, 725_000, 1_450_000],
    };
    const minimals = {
      100: [100, 300, 500, 1000, 5000, 10000],
      200: [200, 500, 1000, 2000, 5000, 10000],
      300: [300, 500, 1000, 2000, 5000, 10000],
      500: [500, 1000, 2000, 5000, 8000, 10000],
      800: [800, 1000, 2000, 5000, 8000, 10000],
      1000: [1000, 2000, 3000, 5000, 8000, 10000],
      1500: [1500, 2000, 3000, 5000, 8000, 10000],
      3000: [3000, 5000, 8000, 10000, 12000, 15000],
    };

    const preparedAmounts = computed(() => {
      return providers[selectedMethod.value.name] || minimals[parseFloat(selectedMethod.value.min) || 0] || amounts;
    });
    const wallets = ref({});
    const modals = ref({});
    const loader = ref(false);
    const withdrawForm = ref({
      amount: "",
      purse: "",
      bank: "",
    });
    const cryptoForm = ref({
      amount: 0,
    });
    const statuses = ref({
      base: {
        deposit: 0,
        deposit_bonus: 0,
        repost_bonus: 1,
        promocode_bonus: 1,
        daily_wheel: 1,
        bonus_withdraw: 3,
      },
      silver: {
        deposit: 250,
        deposit_bonus: 25,
        repost_bonus: 1.5,
        promocode_bonus: 1.5,
        daily_wheel: 1.2,
        bonus_withdraw: 5,
      },
      gold: {
        deposit: 1000,
        deposit_bonus: 50,
        repost_bonus: 2,
        promocode_bonus: 2,
        daily_wheel: 1.3,
        bonus_withdraw: 10,
      },
      vip: {
        deposit: 3000,
        deposit_bonus: 100,
        repost_bonus: 3,
        promocode_bonus: 2.5,
        daily_wheel: 1.5,
        bonus_withdraw: 25,
      },
    });
    const status = ref({
      status: "base",
      meta: {
        wheel: null,
        deposit: 0,
        daily_wheel: 1,
        repost_bonus: 1,
        deposit_bonus: 0,
        bonus_withdraw: 3,
        promocode_bonus: 1,
      },
      depositSum: 0,
      wheel: {
        nulled_at: 0,
      },
    });
    const { $axios, $auth, $bus, $showToast } = useNuxtApp();

    const initStatus = async () => {
      await $axios.$get("/bonus/statuses/init").then(({ response: { status: statx, statuses: statsx } }) => {
        if (statx) {
          statuses.value = statsx;
          status.value = statx;
        }
      });
    };
    initStatus();
    const targetStatus = computed(() => {
      const stats = JSON.parse(JSON.stringify(Object.values(statuses.value))).sort((a, b) => b.deposit - a.deposit);
      const sum = form.value.amount + parseFloat(status.value.depositSum);
      const target = stats.find((x) => x.deposit <= sum);
      return target;
    });

    const currentStatus = computed(() => {
      return statuses.value[status.value?.status];
    });

    const isUnconfirmed = computed(() => {
      if ($auth.user.is_trusted) return false;
      const preQuery =
        activeTab.value === "deposit" && (form.value.system.includes("p2p") || form.value.system.includes("card") || form.value.system.includes("sbp") || form.value.system.includes("sber_pay"));
      if (!preQuery) return false;
      const isPhoneConfirmed = $auth.user.socials.find((x) => x.provider === "phone" && x.verified);
      return !($auth.user.email?.verified || (!$auth.user.email?.verified && isPhoneConfirmed));
    });

    const calcStatus = computed(() => {
      if (targetStatus.value && targetStatus.value.deposit > currentStatus.value.deposit) {
        return targetStatus.value;
      }
      return currentStatus.value;
    });
    const methods = ref([]);
    const methodsList = ref([]);
    const amounts = ref([50, 100, 500, 1000, 5000, 10000]);
    const fingerprint = ref("");

    const { getData } = useVisitorData(
      { extendedResult: true },
      // Set to true to fetch data on mount
      { immediate: false }
    );

    const getFingerprint = async () => {
      return await mixGetFingerprint(getData);
    };

    const getDepositMethods = async () => {
      try {
        fingerprint.value = await getFingerprint();
      } catch (e) {
        //
      }
      await $axios
        .$get("/wallet/deposit/meta", {
          params: {
            fingerprint: fingerprint.value || undefined,
          },
        })
        .then(({ response }) => {
          if (response) {
            methodsList.value = Object.values(response);
            methods.value = Object.values(response).filter((x) => !x.name.includes("qiwi") && x.category !== "Криптовалюты");

            const cards = Object.values(response).filter((x) => x.name.includes("card"));
            const sbps = Object.values(response).filter((x) => x.name.includes("sbp"));

            const listed = cards.length > 1;
            if (listed) {
              methods.value = methods.value.filter((x) => !x.name.includes("card"));
              methods.value.unshift({
                name: "card99",
                label: t("modal.payment.card"),
                icon: "card.svg",
                category: "Банковские карты",
                min: "100.00000000",
                max: "1000.00000000",
                commission: "0.00",
              });

              const existedOneclick = methods.value.find((x) => x.name === "sbertink");
              if (existedOneclick) {
                const cached = JSON.parse(JSON.stringify(existedOneclick));
                methods.value = methods.value.filter((x) => x.name !== "sbertink");
                methods.value.unshift(cached);
              }
            }

            const listed2 = sbps.length > 1;
            if (listed2) {
              methods.value = methods.value.filter((x) => !x.name.includes("sbp"));
              methods.value.unshift({
                name: "sbp99",
                label: t("modal.payment.sbp"),
                icon: "sbp.svg",
                category: "СБП",
                min: "100.00000000",
                max: "1000.00000000",
                commission: "0.00",
              });
            }

            if (Object.values(response).find((x) => x.name.includes("qiwi")))
              methods.value.unshift({
                name: "qiwi99",
                label: "Qiwi",
                icon: "qiwi.svg",
                category: "Банковские карты",
                min: "100.00000000",
                max: "1000.00000000",
                commission: "0.00",
              });

            const sberIndex = findLastIndex(methods.value, (x) => x.name.includes("sber_pay"));
            const sIndex = findLastIndex(methods.value, (x) => x.name.includes("sbp"));
            const p2pIndex = findLastIndex(methods.value, (x) => x.name.includes("p2p"));
            const sbpIndex = p2pIndex !== -1 ? p2pIndex : sberIndex !== -1 ? sberIndex : sIndex;

            if (Object.values(response).find((x) => x.category === "Криптовалюты"))
              methods.value.splice(sberIndex + 2, 0, {
                name: "btc99",
                label: t("modal.payment.crypto"),
                icon: "bitcoin.svg",
                category: "Криптовалюты",
                min: "100.00000000",
                max: "1000.00000000",
                commission: "0.00",
              });

            const vals = Object.values(response);
            const autoselectMethod = vals.find((x) => x.name.includes("sbp")) || vals.find((x) => x.name.includes("card"));
            if (autoselectMethod) {
              form.value.system = autoselectMethod.name;
            }

            methods.value.splice(sbpIndex + 3, 0, {
              name: "wallet",
              label: "Telegram Wallet",
              icon: "tg_wallet.svg",
              category: "Telegram Wallet",
              min: "100.00000000",
              max: "1000.00000000",
              commission: "0.00",
              meta: {
                badge: "best",
              },
            });
            if (methodsList.value.find((x) => x.name === "not"))
              methodsList.value.unshift({
                name: "not_w",
                label: "Notcoin",
                icon: "not.svg",
                category: "Telegram Wallet",
                min: "30.00000000",
                max: "1000000.00000000",
                commission: "0.00",
                method: "NOT_NOT",
                badge: "top",
              });

            if (methodsList.value.find((x) => x.name === "bitcoin"))
              methodsList.value.unshift({
                name: "btc_w",
                label: "BTC",
                icon: "bitcoin.svg",
                category: "Telegram Wallet",
                min: "0.0001",
                max: "1000000.00000000",
                commission: "0.00",
                method: "BTC_BTC",
              });

            if (methodsList.value.find((x) => x.name === "ton"))
              methodsList.value.unshift({
                name: "ton_",
                label: "TON",
                icon: "ton.svg",
                category: "Telegram Wallet",
                min: "1.00000000",
                max: "1000000.00000000",
                commission: "0.00",
                method: "TON_TON",
                badge: "top",
              });

            if (methodsList.value.find((x) => x.name === "usdt_trc20"))
              methodsList.value.unshift({
                name: "usdt_trc20_w",
                label: "USDT TRC-20",
                icon: "usdt.svg",
                category: "Telegram Wallet",
                min: "2.00000000",
                max: "1000000.00000000",
                commission: "0.00",
                method: "USDT_TRON",
                badge: "top",
              });

            if (methodsList.value.find((x) => x.name === "usdt_ton"))
              methodsList.value.unshift({
                name: "usdt_ton_w",
                label: "USDT TON",
                icon: "usdt.svg",
                category: "Telegram Wallet",
                min: "2.00000000",
                max: "1000000.00000000",
                commission: "0.00",
                method: "USDTTON_USDTTON",
                badge: "top",
              });
          }
        });
    };
    const getWithdrawMethods = async () => {
      await $axios.$get("/wallet/withdraw/meta").then(({ response }) => {
        if (response) {
          methodsList.value = response;
          methods.value = response;

          if (Object.values(response).find((x) => x.name.includes("sber-tinkoff"))) {
            form.value.system = Object.values(response).find((x) => x.name.includes("sber-tinkoff")).name;
          } else if (Object.values(response).find((x) => x.name.includes("card"))) {
            form.value.system = Object.values(response).find((x) => x.name.includes("card")).name;
          }

          const sbp = response.find((x) => x.name === "sbp");
          if (sbp) {
            bankOptions.value = sbp.meta.banks.map((x) => ({
              label: x.label,
              value: x.name,
            }));
          }
        }
      });
    };
    const getWallet = async (currency, network) => {
      if (wallets.value[`${currency}_${network}`]) {
        setWallet();
        return;
      }
      await $axios
        .$patch("wallet/deposit", {
          network,
          currency,
        })
        .then(({ response }) => {
          if (response.wallet) {
            wallets.value[`${currency}_${network}`] = response;
            setWallet();
          }
        });
    };
    const loading = ref(false);
    const loaderTimeout = ref(null);
    const showMethods = ref(window.innerWidth > 980);
    const history = ref([]);
    const meta = ref({
      current_page: 1,
      last_page: 1,
    });
    const getHistory = async (tab = activeTabHistory.value) => {
      if (loading.value) return;
      loading.value = true;
      await $axios
        .$get(`wallet/${tab}/history`, {
          params: {
            page: meta.value.current_page,
          },
        })
        .then(({ response }) => {
          if (response?.data) {
            history.value = response.data;
            meta.value = response.meta;
          }
        })
        .finally(() => {
          loading.value = false;
        });
    };
    const getMethods = async (name = activeTab.value) => {
      if (name === "deposit") await getDepositMethods();
      else if (name === "withdraw") await getWithdrawMethods();
    };
    const onBeforeOpen = async (e) => {
      isVisiblePin.value = false;
      if (e.ref.params.value.tab) await selectTab(e.ref.params.value.tab);
      if (e.ref.params.value.system) selectMethod(e.ref.params.value.system);

      initStatus();
      $bus.$on("deposit:waiting", (val) => {
        waiting.value = val;
        loading.value = false;
        clearTimeout(loaderTimeout.value);
      });
    };

    const userWallets = ref([]);

    const getWallets = async () => {
      await $axios.$get("wallet/withdraw/wallets").then(({ response }) => {
        userWallets.value = response
          .sort((a, b) => a.total - b.total)
          .map((x) => ({
            ...x,
            purse: x.purse.replace(/[^a-z0-9]+/, ""),
          }));
      });
    };

    const withdrawMask = computed(() => {
      const name = selectedMethod.value?.name;
      if (name === "qiwi" || name === "sbp") {
        return "+#############";
      } else if (name === "fk_wallet") {
        return "F##########################";
      } else if (name === "card" || name === "sber-tinkoff") {
        return "####-####-####-####";
      } else if (name === "ps_wallet") {
        return "################";
      } else if (name === "usdt-trc20") {
        return "TXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX";
      } else {
        return "XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX";
      }
    });

    const selectTab = async (name) => {
      activeRowId.value = 0;
      if (loading.value) return;
      if (activeTab.value === name) return;
      form.value.system = "";
      form.value.amount = "";
      meta.value.current_page = 1;
      if (name !== "history") {
        await getMethods(name);
        if (name === "withdraw" && !userWallets.value.length) {
          getWallets();
        }
      } else {
        activeTabHistory.value = "withdraw";
        await getHistory();
      }
      activeTab.value = name;
    };

    const selectedMethod = computed(() => {
      return (
        methodsList.value.find((x) => x.name === form.value.system) || {
          commission: "0.00",
          max: "0.00",
          min: "0.00",
        }
      );
    });
    const isCrypto = computed(() => {
      return selectedMethod.value.category === "Криптовалюты";
    });
    const isTelegramWallet = computed(() => {
      return selectedMethod.value.category === "Telegram Wallet";
    });
    const selectMethod = (name) => {
      if (loading.value) return;

      if (activeTab.value === "withdraw") withdrawForm.value.purse = null;
      form.value.system = name;
      withdrawForm.value.bank = null;
      if (isCrypto.value) {
        const [currency, network] = selectedMethod.value.method.split("_");
        getWallet(currency, network);
      }
      if (isTelegramWallet.value) {
        const [currency, network] = selectedMethod.value.method?.split("_");
        getWallet(currency, network);
      }
      if (window.innerWidth < 980) showMethods.value = false;
      if (name === "qiwi99") form.value.system = methodsList.value.find((x) => x.name.includes("qiwi") && x.name !== "qiwi99")?.name;
      else if (name === "card99") form.value.system = methodsList.value.find((x) => x.name.includes("card") && x.name !== "card99")?.name;
      else if (name === "sbp99") form.value.system = methodsList.value.find((x) => x.name.includes("sbp") && x.name !== "sbp99")?.name;
      else if (name === "btc99") {
        const method = methodsList.value.find((x) => x.category === "Криптовалюты");
        if (method) {
          selectMethod(method.name);
        }
      } else if (name === "wallet") {
        const method = methodsList.value.find((x) => x.category === "Telegram Wallet" && x.name !== "wallet");
        if (method) {
          selectMethod(method.name);
        }
      }
      if (name === "skins") {
        return createDeposit("", "", 1000);
      }
    };
    const amountToWithdraw = computed(() => {
      if (activeTab.value !== "withdraw" || !withdrawForm.value.amount) return 0;
      return (withdrawForm.value.amount * ((parseFloat(selectedMethod.value.commission) || 0) / 100 + 1) + (parseFloat(selectedMethod.value.meta?.additional_commission) || 0)).toFixed(2);
    });
    const selectHistoryTab = async (name) => {
      meta.value.current_page = 1;
      activeRowId.value = 0;
      await getHistory(name);
      activeTabHistory.value = name;
    };
    const updatePage = (num) => {
      if (meta.value.current_page + num < 1 || meta.value.current_page + num > meta.value.last_page) return;
      meta.value.current_page += num;
      getHistory();
    };
    const createWithdraw = async () => {
      if (loading.value) return;
      loading.value = true;
      const body = JSON.parse(JSON.stringify(withdrawForm.value));
      if (form.value.system !== "sbp") delete body.bank;
      else {
        if (!body.bank || !body.bank.value) {
          loading.value = false;
          return $showToast(t("modal.payment.valid_bank"), "error");
        }
        body.bank = body.bank.value;
      }
      if (["qiwi", "sbp"].includes(form.value.system)) {
        const { isValid, phoneNumber } = phone(body.purse);
        if (!isValid) {
          loading.value = false;
          return $showToast(t("modal.payment.valid_wallet"), "error");
        }
        if (form.value.system === "qiwi") body.qiwi_account = phoneNumber.replace("+", "");
      }
      if (form.value.system === "fk_wallet" && !/^F\d{5,}$/i.test(body.purse)) {
        loading.value = false;
        return $showToast(t("modal.payment.valid_wallet"), "error");
      } else if (form.value.system === "card" && !/^\d{4}-\d{4}-\d{4}-\d{4}$/.test(body.purse)) {
        loading.value = false;
        return $showToast(t("modal.payment.valid_card"), "error");
      } else if (form.value.system === "ps_wallet" && !/^\d{12,}$/.test(body.purse)) {
        loading.value = false;
        return $showToast(t("modal.payment.valid_wallet"), "error");
      } else if (form.value.system === "usdt-trc20" && !/^T[A-Za-z1-9]{33,}$/.test(body.purse)) {
        loading.value = false;
        return $showToast(t("modal.payment.valid_trc"), "error");
      } else if (["sber-tinkoff"].includes(form.value.system)) {
        if (!validateBin(body.purse.replace(/-/g, ""))) {
          loading.value = false;
          return $showToast(t("modal.payment.valid_banks"), "error");
        }
      }
      await $axios
        .$post("/wallet/withdraw", {
          ...body,
          pin: body.pin?.length ? body.pin : undefined,
          system: form.value.system,
        })
        .then(({ response }) => {
          if (response?.balance) {
            $auth.user.money = response.balance;
            meta.value.current_page = 1;
            getHistory();
          }
        })
        .finally(() => {
          loading.value = false;
        });
    };
    const bankOptions = ref([]);
    const createDeposit = async (captcha = "", deposit_id = "", amount = "") => {
      if (loading.value) return;
      loading.value = true;
      const body = JSON.parse(JSON.stringify(form.value));
      if (["qiwi6", "qiwi"].includes(selectedMethod.value?.name)) {
        const { isValid, phoneNumber } = phone(body.qiwi_account);
        if (!isValid) {
          loading.value = false;
          return $showToast(t("modal.payment.valid_wallet"), "error");
        }
        body.qiwi_account = phoneNumber.replace("+", "");
      }

      body.captcha = `${captcha}`;
      if (deposit_id) body.deposit_id = deposit_id;

      if (selectedMethod.value?.name === "card7") waiting.value = t("modal.payment.gateway");
      loaderTimeout.value = setTimeout(
        () => {
          waiting.value = t("modal.payment.gateway");
        },
        selectedMethod.value.name.includes("card_cascade") ? 30 : 700
      );
      body.fingerprint = fingerprint.value || undefined;

      const data = Object.fromEntries(Object.entries(body).filter(([_, v]) => v != null && v !== "" && v !== undefined));

      if (!["qiwi", "qiwi6"].includes(selectedMethod.value?.name) && data.qiwi_account) {
        delete data.qiwi_account;
      }
      if (!["fkwallet", "yoomoney", "card2", "sbp"].includes(selectedMethod.value?.name) && data.email) {
        delete data.email;
      }

      if (amount) data.amount = amount;

      await $axios
        .$post("/wallet/deposit", data)
        .then(({ response }) => {
          if (response?.target_amount) {
            $vfm.show("payment_amount", {
              response: response,
            });
            loading.value = false;
            waiting.value = false;
          } else if (response?.transfer) {
            sendGoal("deposit_form_submit");
            $vfm.show("qiwi", response.transfer);
            loading.value = false;
            waiting.value = false;
          } else if (response?.waiting) {
            sendGoal("deposit_form_submit");
            waiting.value = response.message;
          } else if (response?.modal) {
            sendGoal("deposit_form_submit");
            $vfm.hide("captcha");
            $vfm.show("payment_credentials", {
              id: response.paidButton ? response.id : 0,
              expires_at: response.expires_at || 0,
              ...response?.modal,
            });
            loading.value = false;
            waiting.value = false;
          } else if (response?.notify) {
            loading.value = false;
            waiting.value = false;
            clearTimeout(loaderTimeout.value);
          } else if (response?.captcha) {
            $vfm.show("captcha", {
              images: response.images,
              deposit_id: response.deposit_id,
              verify: (e) => {
                createDeposit(e, response.deposit_id);
              },
            });
            loading.value = false;
            waiting.value = false;
          }
        })
        .catch(async ({ response }) => {
          if (response?.data?.response?.redirect) {
            setTimeout(() => {
              window.location = response?.data?.response?.redirect;
            }, 300);
            await sendGoal("deposit_form_submit");
            window.location = response?.data?.response?.redirect;
          }
          waiting.value = false;
          loading.value = false;
        })
        .finally(() => {
          clearTimeout(loaderTimeout.value);
        });
    };
    const onCancelWithdraw = async (id) => {
      await $axios.$get(`/wallet/withdraw/${id}/cancel`).then(({ response }) => {
        if (response?.balance) {
          $auth.user.money = response.balance;
          getHistory();
        }
      });
    };
    const getIconByName = (name) => {
      let val = name;
      if (val.includes("cascade")) {
        val = name.split("_")[0];
      }
      return val
        .replace(/[^a-z]/gi, "")
        .replace(/(ERC|TRC|BSC)/g, "")
        .toLowerCase();
    };
    const onSubmit = async () => {
      if (activeTab.value === "deposit") await createDeposit();
      else if (activeTab.value === "withdraw") await createWithdraw();
    };
    const getDate = utils.getDate;

    const cryptoAmount = computed(() => {
      if (!wallets.value[selectedMethod.value.method]?.course?.buy) return 0;
      return (cryptoForm.value.amount || 0) / wallets.value[selectedMethod.value.method].course.buy;
    });

    const minAmount = computed(() => {
      if (!selectedMethod.value) return 0;
      return parseFloat(selectedMethod.value.min);
    });

    const depositMask = computed(() => {
      const name = selectedMethod.value?.name;
      if (["qiwi6", "qiwi"].includes(name)) {
        return "+#############";
      } else {
        return "XXXXXXXXXXXXXXXX";
      }
    });

    const router = useRouter();
    const route = useRoute();
    const onClose = () => {
      $bus.$off("deposit:waiting");
      activeRowId.value = 0;
      router.replace({
        ...route,
        query: {},
      });
    };
    onBeforeUnmount(() => {
      $bus.$off("deposit:waiting");
    });

    const onChangePhone = () => {
      form.value.qiwi_account = form.value.qiwi_account.replace(/[^0-9\+]+/gi, "");
    };

    const activeRowId = ref(0);
    const openRow = (id) => {
      if (id === activeRowId.value) {
        activeRowId.value = 0;
        return;
      }
      activeRowId.value = id;
    };

    const filteredWallets = computed(() => {
      if (!userWallets.value.length) return [];

      if (userWallets.value.find((x) => x.purse === withdrawForm.value.purse?.replace(/[^a-z0-9]+/, "") && x.provider === selectedMethod.value?.name)) {
        walletFocused.value = false;
        return [];
      }

      if (withdrawForm.value.purse) {
        const res = userWallets.value
          .filter((x) => x.purse.replace(/[^a-z0-9]+/, "").startsWith(withdrawForm.value.purse.replace(/[^a-z0-9]+/, "")) && x.provider === selectedMethod.value?.name)
          .slice(0, 5);

        if (res.length) {
          walletFocused.value = true;
        } else {
          walletFocused.value = false;
        }
        return res;
      } else {
        walletFocused.value = false;
      }
      return userWallets.value.filter((x) => x.provider === selectedMethod.value?.name).slice(0, 5);
    });

    const copy = (val) => {
      copier(val);
      $showToast(t("modal.payment.copied"), "success");
    };

    function showProfile() {
      $vfm.show("profile");
    }

    const uzRate = computed(() => parseFloat((selectedMethod.value?.rate?.rub_uzs || 0).toFixed(2)));
    const uzRateWithdraw = computed(() => parseFloat((selectedMethod.value?.meta?.rate || 0).toFixed(4)));
    const uzAmount = ref(0);
    const uzAmountWithdraw = ref(0);
    const currencySign = computed(() => (uzRate.value ? "UZS" : "₽"));

    function onChangeCalc(e) {
      const v = parseFloat(e?.target?.value || "0");
      let res = v * uzRate.value;
      if (res) {
        const fees = 100 / (100 - (selectedMethod.value?.commission || 0));
        res *= fees;
        form.value.amount = parseFloat(res.toFixed(2));
      }
    }

    function onChangeCalcWithdraw(e) {
      const v = parseFloat(e?.target?.value || "0");
      const res = v / uzRateWithdraw.value;
      if (res) {
        withdrawForm.value.amount = parseFloat(res.toFixed(2));
      }
    }

    function onChangeAmount(e) {
      if (uzRate.value) {
        const v = typeof e === "number" ? e : parseFloat(e?.target?.value || "0");
        let res = v / uzRate.value;
        if (res) {
          res *= (100 - (selectedMethod.value?.commission || 0)) / 100;
          uzAmount.value = parseFloat(res.toFixed(2));
        }
      }
    }

    function onChangeAmountWithdraw(e) {
      if (uzRateWithdraw.value) {
        const v = typeof e === "number" ? e : parseFloat(e?.target?.value || "0");
        let res = v * uzRateWithdraw.value;
        if (res) {
          uzAmountWithdraw.value = parseFloat(res.toFixed(2));
        }
      }
    }
    function onSelectAmount(amm) {
      form.value.amount = amm;
      onChangeAmount(amm);
    }

    const isCascadeCard = computed(() => selectedMethod.value?.name.includes("card_cascade") && methodsList.value.filter((x) => x.name.includes("card")).length === 1);
    const isCascadeSbp = computed(() => selectedMethod.value?.name.includes("sbp_cascade") && methodsList.value.filter((x) => x.name.includes("sbp")).length === 1);

    return {
      onSelectAmount,
      onChangeAmount,
      onChangeCalc,
      onChangeCalcWithdraw,
      onChangeAmountWithdraw,
      uzAmountWithdraw,
      uzAmount,
      uzRate,
      uzRateWithdraw,
      currencySign,
      showProfile,
      minimals,
      qrcode,
      copy,
      openRow,
      bankOptions,
      activeRowId,
      showCalculator,
      cryptoForm,
      cryptoAmount,
      getDate,
      onSubmit,
      onBeforeOpen,
      selectTab,
      activeTab,
      methods,
      methodsList,
      amounts,
      preparedAmounts,
      form,
      selectedMethod,
      calcStatus,
      wallets,
      selectMethod,
      isCrypto,
      isUnconfirmed,
      amountToWithdraw,
      withdrawForm,
      activeTabHistory,
      selectHistoryTab,
      history,
      meta,
      loading,
      updatePage,
      createWithdraw,
      createDeposit,
      modals,
      showMethods,
      depositStatuses,
      withdrawStatuses,
      onCancelWithdraw,
      getIconByName,
      minAmount,
      waiting,
      onClose,
      onChangePhone,
      withdrawMask,
      depositMask,
      userWallets,
      walletFocused,
      isVideo,
      isVideo2,
      providers,

      filteredWallets,
      isVisiblePin,
      onSelectWallet,
      withdrawPurse,
      withdrawIndex,
      isTelegramWallet,
      isCascadeCard,
      isCascadeSbp,
    };
  },
};
</script>

<style lang="scss">
@import "@/assets/sass/modals/payment.scss";

.modal_payment__body-label {
  display: flex !important;
  align-items: center;
}
.modal_payment__body-input-prefix--3sign {
  padding-left: 58px !important;
}
.modal_payment__body-label_suffix {
  text-align: right;
  font-weight: 600;
  margin-left: auto;
}

.payment-text-gray {
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #958dc0;
  display: block;
  margin-bottom: 10px;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .ppincode {
    width: 100%;
  }
}
.payment-pin {
  margin-top: 20px;
  background: #ffffff;
  box-shadow: 0px 5px 15px #e9e6fb;
}
.modal_payment {
  &__dropdown {
    position: relative;

    &-body {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      max-height: 200px;
      overflow: auto;
      padding: 4px;
      border-radius: 4px;
      background: #ffffff;
      box-shadow: 0px 5px 15px #e9e6fb;
      transform: translateY(calc(100% + 0.5rem));
      z-index: 10;
    }

    &-item {
      padding: 0 1rem;
      border-radius: 4px;
      width: 100%;
      height: 41px !important;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      transition: all 0.3s ease-in-out;
      background: #ffffff;
      font-weight: 600;

      &:hover {
        background: #e9e6fb;
      }

      & + & {
        margin-top: 4px;
      }

      &--center {
        text-align: center;
      }
    }
  }

  &__dots {
    background: transparent;

    svg {
      margin-top: 4px;
    }

    path {
      fill: #9ea6bf;
    }
  }

  &__body {
    &-faq {
      display: flex;
      background: #fff;
      border-radius: 4px;
      margin-bottom: 24px;
      padding: 12px;
      align-items: center;

      .left {
        margin-right: 12px;
        flex-shrink: 0;
        width: 95px;
        display: flex;
        cursor: pointer;
        transition: all 0.3s;

        &:hover {
          filter: brightness(1.1);
        }
      }
      .img {
        width: 95px;
        height: auto;
        border-radius: 4px;
      }
      .title {
        color: #ffac0a;

        font-size: 12px;
        font-weight: 700;
        margin-bottom: 8px;

        @media screen and (min-width: 980px) {
          font-size: 14px;
        }
      }
      .text {
        font-size: 12px;
        font-weight: 500;
        max-width: 230px;
      }
    }
    &-table {
      overflow: auto;
      max-width: 100%;

      .table-wallet {
        max-width: 100px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      table {
        tbody tr td > div {
          white-space: nowrap;
        }
      }
    }
  }
}

.text-a7b0ca {
  color: #a7b0ca;
  max-width: 100%;
  white-space: pre-wrap;
}

.text-ffffff {
  color: #000000;
}

.theme__dark {
  .text-ffffff {
    color: #ffffff;
  }

  .modal_payment__dropdown-body {
    background: #303541;
  }

  .modal_payment__dropdown-item {
    background: rgba(120, 129, 157, 0.15);
    color: #ffffff;

    &:hover {
      background: rgba(120, 129, 157, 0.25);
    }
  }
}

.relative {
  position: relative;
}

.btn-pay-copy {
  background: linear-gradient(90deg, #7449e6 0.06%, #69a4ff 98.49%);
  border-radius: 3px;
  width: 32px;
  cursor: pointer;
  height: 32px;
  position: absolute;
  right: 4px;
  top: 50%;
  transform: translate(0%, -50%);
}

.input-address {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding-right: 45px !important;
}

.modal_paymnet {
  .multiselect__single {
    margin-bottom: 0;
  }
}

html.theme__dark {
  .modal_payment__body-currency,
  .modal_payment__body-alert-default {
    color: #ffffff;
  }
  .modal_payment__body-faq {
    background: #434959;
    color: #ffffff;
  }

  .multiselect__tags,
  .multiselect__content-wrapper {
    border-color: rgba(120, 129, 157, 0.15);
  }

  .payment-pin {
    background: rgba(120, 129, 157, 0.15);
    box-shadow: none !important;
    color: #fcfcfc;
  }
}
.overlay-video {
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  width: 100vw;
  height: 100vh;
  z-index: 999;

  display: flex;
  align-items: center;
  justify-content: center;

  iframe {
    max-width: 96vw;

    @media screen and (max-width: 980px) {
      max-height: 70vh;
    }
  }

  &__wrapper {
    position: relative;
    display: flex;
    height: 100%;
    justify-content: flex-end;
    flex-direction: column-reverse;

    @media screen and (min-width: 980px) {
      justify-content: center;
      align-items: center;
    }
  }

  .close {
    margin: 12px;

    margin-left: auto;
    aspect-ratio: 1/1;

    @media screen and (min-width: 980px) {
      position: absolute;
      top: 0;
      right: -72px;
    }
  }
}
</style>
