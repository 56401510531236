<template>
  <svg
    v-if="!dark"
    id="epQFcirRlVE1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 359.98395 360.294266"
    shape-rendering="geometricPrecision"
    text-rendering="geometricPrecision"
    style="background-color: transparent"
  >
    <g transform="matrix(2.16 0 0 2.16 179.999 180.133)">
      <path d="M33.033,0c0-18.244-14.79-33.033-33.033-33.033s-33.033,14.789-33.033,33.033s14.79,33.033,33.033,33.033s33.033-14.789,33.033-33.033Z" fill="#bdc8ec" stroke-width="0" />
    </g>
    <g id="epQFcirRlVE4_tr" transform="translate(180.107,179.927) rotate(0)">
      <g transform="scale(1.07,1.07) translate(-60,-60)">
        <g transform="matrix(2.16 0 0 2.16-43.076-58.766)">
          <g id="epQFcirRlVE6_ts" transform="translate(36.1,98.125) scale(0,0)">
            <g transform="translate(0,0)">
              <path
                d="M0.776,-2.465c0,0-.184-.049-.184-.049-1.456-.368-2.937.513-3.305,1.968-.369,1.457.511,2.935,1.967,3.305c0,0,.096.026.096.026.238.065.479.097.714.097c1.199,0,2.297-.798,2.625-2.009.394-1.451-.462-2.945-1.913-3.338Z"
                fill="#bdc8ec"
                stroke-width="0"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
    <g id="epQFcirRlVE8_tr" transform="translate(180.107,179.927) rotate(0)">
      <g transform="scale(1.07,1.07) translate(-60,-60)">
        <g transform="matrix(2.16 0 0 2.16-43.07672-58.76484)">
          <g id="epQFcirRlVE10_ts" transform="translate(25.292,93.599) scale(0,0)">
            <g transform="translate(0,0)">
              <path
                d="M1.34,-2.185c-1.294-.763-2.964-.327-3.725.97-.761,1.294-.327,2.961.968,3.722c0,0,.154.088.154.088.419.24.868.352,1.312.352.937,0,1.846-.505,2.352-1.391.745-1.304.243-2.995-1.061-3.741Z"
                fill="#bdc8ec"
                stroke-width="0"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
    <g id="epQFcirRlVE12_tr" transform="translate(180.107,179.927) rotate(0)">
      <g transform="scale(1.07,1.07) translate(-60,-60)">
        <g transform="matrix(2.16 0 0 2.16-43.07624-58.76496)">
          <g id="epQFcirRlVE14_ts" transform="translate(16.064,86.556) scale(0,0)">
            <g transform="translate(0,0)">
              <path
                d="M1.872,-1.841c-1.062-1.063-2.785-1.063-3.846,0-1.063,1.062-1.063,2.784,0,3.845c0,0,.102.104.102.104.531.53,1.227.796,1.923.796s1.393-.266,1.925-.796c1.061-1.063,1.061-2.785,0-3.848c0,0-.104-.101-.104-.101Z"
                fill="#bdc8ec"
                stroke-width="0"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
    <g id="epQFcirRlVE16_tr" transform="translate(180.107,179.927) rotate(0)">
      <g transform="scale(1.07,1.07) translate(-60,-60)">
        <g transform="matrix(2.16 0 0 2.16-43.07712-58.76552)">
          <g id="epQFcirRlVE18_ts" transform="translate(8.957,77.247) scale(0,0)">
            <g transform="translate(0,0)">
              <path
                d="M2.299,-1.263c-.77-1.291-2.439-1.712-3.731-.941-1.29.77-1.711,2.438-.94,3.727c0,0,.046.083.046.083.502.878,1.419,1.369,2.362,1.369.459,0,.923-.115,1.349-.358c1.304-.745,1.759-2.407,1.013-3.711c0,0-.099-.169-.099-.169Z"
                fill="#bdc8ec"
                stroke-width="0"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
    <g id="epQFcirRlVE20_tr" transform="translate(180.107,179.927) rotate(0)">
      <g transform="scale(1.07,1.07) translate(-60,-60)">
        <g transform="matrix(2.16 0 0 2.16-43.07576-58.76552)">
          <g id="epQFcirRlVE22_ts" transform="translate(4.486,66.522) scale(0,0)">
            <g transform="translate(0,0)">
              <path
                d="M2.644,-0.495c0,0-.039-.14-.039-.14-.393-1.45-1.885-2.303-3.336-1.913-1.45.394-2.308,1.888-1.914,3.338c0,0,.039.14.039.14.329,1.211,1.425,2.008,2.623,2.008.235,0,.476-.032.713-.095c1.451-.394,2.309-1.888,1.914-3.338Z"
                fill="#bdc8ec"
                stroke-width="0"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
    <g id="epQFcirRlVE24_tr" transform="translate(180.107,179.927) rotate(0)">
      <g transform="scale(1.07,1.07) translate(-60,-60)">
        <g transform="matrix(2.16 0 0 2.16 12.962279 3.966722)">
          <path
            d="M-20.254,26.036c0-21.314,15.972-38.958,36.574-41.62c0,0-3.217,3.217-3.217,3.217-1.062,1.061-1.062,2.784,0,3.846.532.532,1.228.797,1.923.797.696,0,1.392-.265,1.924-.797c0,0,7.681-7.681,7.681-7.681c1.063-1.062,1.063-2.785,0-3.847c0,0-7.681-7.681-7.681-7.681-1.061-1.063-2.786-1.063-3.847,0-1.062,1.062-1.062,2.785,0,3.847c0,0,2.861,2.86,2.861,2.86-23.438,2.855-41.658,22.863-41.658,47.059c0,0,0,.073,0,.073c0,1.501,1.218,2.684,2.72,2.684s2.72-1.254,2.72-2.757Z"
            fill="#bdc8ec"
            stroke-width="0"
          />
        </g>
      </g>
    </g>
    <g id="epQFcirRlVE27_tr" transform="translate(180.107,179.927) rotate(0)">
      <g transform="scale(1.07,1.07) translate(-60,-60)">
        <g transform="matrix(2.16 0 0 2.16-43.0768-58.76672)">
          <g id="epQFcirRlVE29_ts" transform="translate(90.855,43.342) scale(0,0)">
            <g transform="translate(0,0)">
              <path
                d="M-2.645,0.791c0,0,.038.14.038.14.329,1.211,1.426,2.007,2.624,2.007.235,0,.475-.03.714-.095c1.449-.394,2.307-1.888,1.912-3.338c0,0-.038-.139-.038-.139-.393-1.449-1.887-2.304-3.337-1.913-1.449.393-2.306,1.887-1.913,3.338Z"
                fill="#e8e5ff"
                stroke-width="0"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
    <g id="epQFcirRlVE31_tr" transform="translate(180.107,179.927) rotate(0)">
      <g transform="scale(1.07,1.07) translate(-60,-60)">
        <g transform="matrix(2.16 0 0 2.16-43.0756-58.76616)">
          <g id="epQFcirRlVE33_ts" transform="translate(86.385,32.526) scale(0,0)">
            <g transform="translate(0,0)">
              <path
                d="M-2.299,1.649c.508.854,1.411,1.328,2.337,1.328.475,0,.955-.124,1.391-.386c1.292-.769,1.713-2.439.942-3.729c0,0-.045-.079-.045-.079-.746-1.305-2.407-1.759-3.71-1.013-1.306.746-1.759,2.407-1.015,3.712c0,0,.1.167.1.167Z"
                fill="#e8e5ff"
                stroke-width="0"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
    <g id="epQFcirRlVE35_tr" transform="translate(180.107,179.927) rotate(0)">
      <g transform="scale(1.07,1.07) translate(-60,-60)">
        <g transform="matrix(2.16 0 0 2.16-43.07748-58.76576)">
          <g id="epQFcirRlVE37_ts" transform="translate(79.278,23.336) scale(0,0)">
            <g transform="translate(0,0)">
              <path
                d="M-1.872,2.108c.531.531,1.226.796,1.923.796.695,0,1.393-.265,1.922-.796c1.064-1.062,1.064-2.785,0-3.847c0,0-.1-.102-.1-.102-1.061-1.063-2.786-1.063-3.848,0-1.062,1.062-1.062,2.785,0,3.846c0,0,.103.103.103.103Z"
                fill="#e8e5ff"
                stroke-width="0"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
    <g id="epQFcirRlVE39_tr" transform="translate(180.107,179.927) rotate(0)">
      <g transform="scale(1.07,1.07) translate(-60,-60)">
        <g transform="matrix(2.16 0 0 2.16-43.07716-58.7662)">
          <g id="epQFcirRlVE41_ts" transform="translate(70.051,16.17) scale(0,0)">
            <g transform="translate(0,0)">
              <path
                d="M-1.339,2.573c.433.255.907.375,1.375.375.934,0,1.841-.48,2.347-1.344.762-1.294.328-2.96-.968-3.721c0,0-.152-.089-.152-.089-1.304-.742-2.917-.264-3.664,1.041-.744,1.304-.242,2.993,1.062,3.738Z"
                fill="#e8e5ff"
                stroke-width="0"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
    <g id="epQFcirRlVE43_tr" transform="translate(180.107,179.927) rotate(0)">
      <g transform="scale(1.07,1.07) translate(-60,-60)">
        <g transform="matrix(2.16 0 0 2.16-43.07672-58.76488)">
          <g id="epQFcirRlVE45_ts" transform="translate(59.242,11.743) scale(0,0)">
            <g transform="translate(0,0)">
              <path
                d="M-0.776,2.755c0,0,.184.048.184.048.226.057.448.084.669.084c1.217,0,2.324-.82,2.637-2.052.369-1.456-.513-2.936-1.968-3.305c0,0-.096-.025-.096-.025-1.449-.393-2.944.463-3.339,1.913-.393,1.449.463,2.942,1.913,3.337Z"
                fill="#e8e5ff"
                stroke-width="0"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
    <g id="epQFcirRlVE47_tr" transform="translate(180.107,179.927) rotate(0)">
      <g transform="scale(1.07,1.07) translate(-60,-60)">
        <g transform="matrix(2.16 0 0 2.16 106.822719 116.158)">
          <path
            d="M22.974,-28.66c-1.502,0-2.72,1.254-2.72,2.756c0,21.316-15.973,38.958-36.573,41.621c0,0,3.215-3.217,3.215-3.217c1.063-1.061,1.063-2.784,0-3.846-1.062-1.063-2.785-1.063-3.846,0c0,0-7.682,7.682-7.682,7.682-1.063,1.061-1.063,2.784,0,3.845c0,0,7.682,7.682,7.682,7.682.53.531,1.227.797,1.924.797.696,0,1.393-.266,1.922-.797c1.063-1.062,1.063-2.785,0-3.846c0,0-2.861-2.862-2.861-2.862c23.438-2.854,41.659-22.861,41.659-47.059c0,0,0-.071,0-.071c0-1.502-1.217-2.685-2.72-2.685Z"
            fill="#e8e5ff"
            stroke-width="0"
          />
        </g>
      </g>
    </g>
    <g transform="matrix(1.464825 0 0 1.464825 141.941504 129.778868)">
      <path
        d="M29.2316,61.914c0,0,.1186,6.3623.1186,6.3623s-8.408,0-8.408,0s.1142-6.5959.1142-6.5959c-3.4134-.3553-6.5655-1.0372-9.4564-2.0457-4.41166-1.557-8.27831-3.9964-11.6-7.3181c0,0,5.83891-9.3422,5.83891-9.3422c3.11408,2.9064,6.38389,5.1382,9.80939,6.6953c1.7652.8023,3.627,1.398,5.5852,1.7869c0,0,.2258-13.0426.2258-13.0426-3.3428-.6755-6.3178-1.5428-8.9251-2.602-3.2698-1.3495-5.78702-3.1141-7.55167-5.294-1.71274-2.1798-2.56911-4.8787-2.56911-8.0966c0-2.5951.59686-4.9306,1.79059-7.0067c1.24564-2.1279,2.98433-3.9185,5.21609-5.3718s4.7749-2.54315,7.6295-3.26977c2.0246-.54229,4.1372-.89571,6.338-1.06026c0,0-.1098-5.71287-.1098-5.71287s8.408,0,8.408,0-.1045,5.86685-.1045,5.86685c3.1666.329,6.056.96845,8.6682,1.91836c3.9965,1.45324,7.396,3.63309,10.1987,6.53959c0,0-5.3718,8.3302-5.3718,8.3302-2.2837-2.128-5.0085-3.8148-8.1745-5.0604-1.7298-.7208-3.5637-1.2414-5.5018-1.5617c0,0-.2244,12.5984-.2244,12.5984c2.1327.353,4.1972.7743,6.1933,1.2639c2.8027.6228,5.268,1.5052,7.396,2.647c2.1798,1.0899,3.8926,2.5691,5.1382,4.4376c1.2975,1.8684,1.9463,4.2299,1.9463,7.0845c0,3.7369-1.064,6.9548-3.1919,9.6537-2.128,2.6469-5.0604,4.697-8.7973,6.1503-3.1254,1.172-6.6683,1.8539-10.6287,2.0457c0,0,0,0,0,0Z"
        fill="#e8e5ff"
        fill-rule="evenodd"
        stroke-width="0"
      />
      <path
        d="M33.0093,51.3045c-1.2621.3156-2.5834.5245-3.9639.6269c0,0-.2326-12.4752-.2326-12.4752.8584.1025,1.7382.2372,2.6395.4041c1.4013.2076,2.6988.5449,3.8926,1.0121c1.2456.4152,2.2577.9861,3.0362,1.7127.7785.6747,1.1678,1.5571,1.1678,2.647c0,1.6089-.6228,2.9065-1.8685,3.8926-1.1937.9861-2.7507,1.7127-4.6711,2.1798c0,0,0,0,0,0Z"
        fill="#bdc8ec"
        fill-rule="evenodd"
        stroke-width="0"
      />
      <path
        d="M23.5815,15.7944c-1.1121.1159-2.1475.3007-3.1064.5545-1.7646.4153-3.166,1.0381-4.204,1.8685s-1.5571,1.8684-1.5571,3.1141c0,1.1937.3634,2.1798,1.09,2.9584.7785.7785,1.8165,1.4272,3.1141,1.9463c1.3494.4671,2.8545.8823,4.5154,1.2456.1241.0233.2488.0463.3741.0689c0,0-.2261-11.7563-.2261-11.7563s0,0,0,0Z"
        fill="#bdc8ec"
        fill-rule="evenodd"
        stroke-width="0"
      />
    </g>
  </svg>
  <svg
    v-else
    id="eBIictmx2RG1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 359.98395 360.294266"
    shape-rendering="geometricPrecision"
    text-rendering="geometricPrecision"
    style="background-color: transparent"
  >
    <g transform="matrix(2.16 0 0 2.16 179.999 180.133)">
      <path d="M33.033,0c0-18.244-14.79-33.033-33.033-33.033s-33.033,14.789-33.033,33.033s14.79,33.033,33.033,33.033s33.033-14.789,33.033-33.033Z" fill="#545b71" stroke-width="0" />
    </g>
    <g id="eBIictmx2RG4_tr" transform="translate(180.107,179.927) rotate(0)">
      <g transform="scale(1.07,1.07) translate(-60,-60)">
        <g transform="matrix(2.16 0 0 2.16-43.076-58.766)">
          <g id="eBIictmx2RG6_ts" transform="translate(36.1,98.125) scale(0,0)">
            <g transform="translate(0,0)">
              <path
                d="M0.776,-2.465c0,0-.184-.049-.184-.049-1.456-.368-2.937.513-3.305,1.968-.369,1.457.511,2.935,1.967,3.305c0,0,.096.026.096.026.238.065.479.097.714.097c1.199,0,2.297-.798,2.625-2.009.394-1.451-.462-2.945-1.913-3.338Z"
                fill="#545b71"
                stroke-width="0"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
    <g id="eBIictmx2RG8_tr" transform="translate(180.107,179.927) rotate(0)">
      <g transform="scale(1.07,1.07) translate(-60,-60)">
        <g transform="matrix(2.16 0 0 2.16-43.07672-58.76484)">
          <g id="eBIictmx2RG10_ts" transform="translate(25.292,93.599) scale(0,0)">
            <g transform="translate(0,0)">
              <path
                d="M1.34,-2.185c-1.294-.763-2.964-.327-3.725.97-.761,1.294-.327,2.961.968,3.722c0,0,.154.088.154.088.419.24.868.352,1.312.352.937,0,1.846-.505,2.352-1.391.745-1.304.243-2.995-1.061-3.741Z"
                fill="#545b71"
                stroke-width="0"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
    <g id="eBIictmx2RG12_tr" transform="translate(180.107,179.927) rotate(0)">
      <g transform="scale(1.07,1.07) translate(-60,-60)">
        <g transform="matrix(2.16 0 0 2.16-43.07624-58.76496)">
          <g id="eBIictmx2RG14_ts" transform="translate(16.064,86.556) scale(0,0)">
            <g transform="translate(0,0)">
              <path
                d="M1.872,-1.841c-1.062-1.063-2.785-1.063-3.846,0-1.063,1.062-1.063,2.784,0,3.845c0,0,.102.104.102.104.531.53,1.227.796,1.923.796s1.393-.266,1.925-.796c1.061-1.063,1.061-2.785,0-3.848c0,0-.104-.101-.104-.101Z"
                fill="#545b71"
                stroke-width="0"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
    <g id="eBIictmx2RG16_tr" transform="translate(180.107,179.927) rotate(0)">
      <g transform="scale(1.07,1.07) translate(-60,-60)">
        <g transform="matrix(2.16 0 0 2.16-43.07712-58.76552)">
          <g id="eBIictmx2RG18_ts" transform="translate(8.957,77.247) scale(0,0)">
            <g transform="translate(0,0)">
              <path
                d="M2.299,-1.263c-.77-1.291-2.439-1.712-3.731-.941-1.29.77-1.711,2.438-.94,3.727c0,0,.046.083.046.083.502.878,1.419,1.369,2.362,1.369.459,0,.923-.115,1.349-.358c1.304-.745,1.759-2.407,1.013-3.711c0,0-.099-.169-.099-.169Z"
                fill="#545b71"
                stroke-width="0"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
    <g id="eBIictmx2RG20_tr" transform="translate(180.107,179.927) rotate(0)">
      <g transform="scale(1.07,1.07) translate(-60,-60)">
        <g transform="matrix(2.16 0 0 2.16-43.07576-58.76552)">
          <g id="eBIictmx2RG22_ts" transform="translate(4.486,66.522) scale(0,0)">
            <g transform="translate(0,0)">
              <path
                d="M2.644,-0.495c0,0-.039-.14-.039-.14-.393-1.45-1.885-2.303-3.336-1.913-1.45.394-2.308,1.888-1.914,3.338c0,0,.039.14.039.14.329,1.211,1.425,2.008,2.623,2.008.235,0,.476-.032.713-.095c1.451-.394,2.309-1.888,1.914-3.338Z"
                fill="#545b71"
                stroke-width="0"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
    <g id="eBIictmx2RG24_tr" transform="translate(180.107,179.927) rotate(0)">
      <g transform="scale(1.07,1.07) translate(-60,-60)">
        <g transform="matrix(2.16 0 0 2.16 12.962279 3.966722)">
          <path
            d="M-20.254,26.036c0-21.314,15.972-38.958,36.574-41.62c0,0-3.217,3.217-3.217,3.217-1.062,1.061-1.062,2.784,0,3.846.532.532,1.228.797,1.923.797.696,0,1.392-.265,1.924-.797c0,0,7.681-7.681,7.681-7.681c1.063-1.062,1.063-2.785,0-3.847c0,0-7.681-7.681-7.681-7.681-1.061-1.063-2.786-1.063-3.847,0-1.062,1.062-1.062,2.785,0,3.847c0,0,2.861,2.86,2.861,2.86-23.438,2.855-41.658,22.863-41.658,47.059c0,0,0,.073,0,.073c0,1.501,1.218,2.684,2.72,2.684s2.72-1.254,2.72-2.757Z"
            fill="#545b71"
            stroke-width="0"
          />
        </g>
      </g>
    </g>
    <g id="eBIictmx2RG27_tr" transform="translate(180.107,179.927) rotate(0)">
      <g transform="scale(1.07,1.07) translate(-60,-60)">
        <g transform="matrix(2.16 0 0 2.16-43.0768-58.76672)">
          <g id="eBIictmx2RG29_ts" transform="translate(90.855,43.342) scale(0,0)">
            <g transform="translate(0,0)">
              <path
                d="M-2.645,0.791c0,0,.038.14.038.14.329,1.211,1.426,2.007,2.624,2.007.235,0,.475-.03.714-.095c1.449-.394,2.307-1.888,1.912-3.338c0,0-.038-.139-.038-.139-.393-1.449-1.887-2.304-3.337-1.913-1.449.393-2.306,1.887-1.913,3.338Z"
                fill="#7380a8"
                stroke-width="0"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
    <g id="eBIictmx2RG31_tr" transform="translate(180.107,179.927) rotate(0)">
      <g transform="scale(1.07,1.07) translate(-60,-60)">
        <g transform="matrix(2.16 0 0 2.16-43.0756-58.76616)">
          <g id="eBIictmx2RG33_ts" transform="translate(86.385,32.526) scale(0,0)">
            <g transform="translate(0,0)">
              <path
                d="M-2.299,1.649c.508.854,1.411,1.328,2.337,1.328.475,0,.955-.124,1.391-.386c1.292-.769,1.713-2.439.942-3.729c0,0-.045-.079-.045-.079-.746-1.305-2.407-1.759-3.71-1.013-1.306.746-1.759,2.407-1.015,3.712c0,0,.1.167.1.167Z"
                fill="#7380a8"
                stroke-width="0"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
    <g id="eBIictmx2RG35_tr" transform="translate(180.107,179.927) rotate(0)">
      <g transform="scale(1.07,1.07) translate(-60,-60)">
        <g transform="matrix(2.16 0 0 2.16-43.07748-58.76576)">
          <g id="eBIictmx2RG37_ts" transform="translate(79.278,23.336) scale(0,0)">
            <g transform="translate(0,0)">
              <path
                d="M-1.872,2.108c.531.531,1.226.796,1.923.796.695,0,1.393-.265,1.922-.796c1.064-1.062,1.064-2.785,0-3.847c0,0-.1-.102-.1-.102-1.061-1.063-2.786-1.063-3.848,0-1.062,1.062-1.062,2.785,0,3.846c0,0,.103.103.103.103Z"
                fill="#7380a8"
                stroke-width="0"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
    <g id="eBIictmx2RG39_tr" transform="translate(180.107,179.927) rotate(0)">
      <g transform="scale(1.07,1.07) translate(-60,-60)">
        <g transform="matrix(2.16 0 0 2.16-43.07716-58.7662)">
          <g id="eBIictmx2RG41_ts" transform="translate(70.051,16.17) scale(0,0)">
            <g transform="translate(0,0)">
              <path
                d="M-1.339,2.573c.433.255.907.375,1.375.375.934,0,1.841-.48,2.347-1.344.762-1.294.328-2.96-.968-3.721c0,0-.152-.089-.152-.089-1.304-.742-2.917-.264-3.664,1.041-.744,1.304-.242,2.993,1.062,3.738Z"
                fill="#7380a8"
                stroke-width="0"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
    <g id="eBIictmx2RG43_tr" transform="translate(180.107,179.927) rotate(0)">
      <g transform="scale(1.07,1.07) translate(-60,-60)">
        <g transform="matrix(2.16 0 0 2.16-43.07672-58.76488)">
          <g id="eBIictmx2RG45_ts" transform="translate(59.242,11.743) scale(0,0)">
            <g transform="translate(0,0)">
              <path
                d="M-0.776,2.755c0,0,.184.048.184.048.226.057.448.084.669.084c1.217,0,2.324-.82,2.637-2.052.369-1.456-.513-2.936-1.968-3.305c0,0-.096-.025-.096-.025-1.449-.393-2.944.463-3.339,1.913-.393,1.449.463,2.942,1.913,3.337Z"
                fill="#7380a8"
                stroke-width="0"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
    <g id="eBIictmx2RG47_tr" transform="translate(180.107,179.927) rotate(0)">
      <g transform="scale(1.07,1.07) translate(-60,-60)">
        <g transform="matrix(2.16 0 0 2.16 106.822719 116.158)">
          <path
            d="M22.974,-28.66c-1.502,0-2.72,1.254-2.72,2.756c0,21.316-15.973,38.958-36.573,41.621c0,0,3.215-3.217,3.215-3.217c1.063-1.061,1.063-2.784,0-3.846-1.062-1.063-2.785-1.063-3.846,0c0,0-7.682,7.682-7.682,7.682-1.063,1.061-1.063,2.784,0,3.845c0,0,7.682,7.682,7.682,7.682.53.531,1.227.797,1.924.797.696,0,1.393-.266,1.922-.797c1.063-1.062,1.063-2.785,0-3.846c0,0-2.861-2.862-2.861-2.862c23.438-2.854,41.659-22.861,41.659-47.059c0,0,0-.071,0-.071c0-1.502-1.217-2.685-2.72-2.685Z"
            fill="#7380a8"
            stroke-width="0"
          />
        </g>
      </g>
    </g>
    <g transform="matrix(1.464825 0 0 1.464825 141.941504 129.778868)">
      <path
        d="M29.2316,61.914c0,0,.1186,6.3623.1186,6.3623s-8.408,0-8.408,0s.1142-6.5959.1142-6.5959c-3.4134-.3553-6.5655-1.0372-9.4564-2.0457-4.41166-1.557-8.27831-3.9964-11.6-7.3181c0,0,5.83891-9.3422,5.83891-9.3422c3.11408,2.9064,6.38389,5.1382,9.80939,6.6953c1.7652.8023,3.627,1.398,5.5852,1.7869c0,0,.2258-13.0426.2258-13.0426-3.3428-.6755-6.3178-1.5428-8.9251-2.602-3.2698-1.3495-5.78702-3.1141-7.55167-5.294-1.71274-2.1798-2.56911-4.8787-2.56911-8.0966c0-2.5951.59686-4.9306,1.79059-7.0067c1.24564-2.1279,2.98433-3.9185,5.21609-5.3718s4.7749-2.54315,7.6295-3.26977c2.0246-.54229,4.1372-.89571,6.338-1.06026c0,0-.1098-5.71287-.1098-5.71287s8.408,0,8.408,0-.1045,5.86685-.1045,5.86685c3.1666.329,6.056.96845,8.6682,1.91836c3.9965,1.45324,7.396,3.63309,10.1987,6.53959c0,0-5.3718,8.3302-5.3718,8.3302-2.2837-2.128-5.0085-3.8148-8.1745-5.0604-1.7298-.7208-3.5637-1.2414-5.5018-1.5617c0,0-.2244,12.5984-.2244,12.5984c2.1327.353,4.1972.7743,6.1933,1.2639c2.8027.6228,5.268,1.5052,7.396,2.647c2.1798,1.0899,3.8926,2.5691,5.1382,4.4376c1.2975,1.8684,1.9463,4.2299,1.9463,7.0845c0,3.7369-1.064,6.9548-3.1919,9.6537-2.128,2.6469-5.0604,4.697-8.7973,6.1503-3.1254,1.172-6.6683,1.8539-10.6287,2.0457c0,0,0,0,0,0Z"
        fill="#7380a7"
        fill-rule="evenodd"
        stroke-width="0"
      />
      <path
        d="M33.0093,51.3045c-1.2621.3156-2.5834.5245-3.9639.6269c0,0-.2326-12.4752-.2326-12.4752.8584.1025,1.7382.2372,2.6395.4041c1.4013.2076,2.6988.5449,3.8926,1.0121c1.2456.4152,2.2577.9861,3.0362,1.7127.7785.6747,1.1678,1.5571,1.1678,2.647c0,1.6089-.6228,2.9065-1.8685,3.8926-1.1937.9861-2.7507,1.7127-4.6711,2.1798c0,0,0,0,0,0Z"
        fill="#545b71"
        fill-rule="evenodd"
        stroke-width="0"
      />
      <path
        d="M23.5815,15.7944c-1.1121.1159-2.1475.3007-3.1064.5545-1.7646.4153-3.166,1.0381-4.204,1.8685s-1.5571,1.8684-1.5571,3.1141c0,1.1937.3634,2.1798,1.09,2.9584.7785.7785,1.8165,1.4272,3.1141,1.9463c1.3494.4671,2.8545.8823,4.5154,1.2456.1241.0233.2488.0463.3741.0689c0,0-.2261-11.7563-.2261-11.7563s0,0,0,0Z"
        fill="#545b71"
        fill-rule="evenodd"
        stroke-width="0"
      />
    </g>
  </svg>
</template>

<script setup lang="ts">
import { useStore } from "@/store/index";
import { storeToRefs } from "pinia";

const store = useStore();
const { dark } = storeToRefs(store);
</script>

<style>
#eBIictmx2RG4_tr {
  animation: eBIictmx2RG4_tr__tr 3000ms linear infinite normal forwards;
}
@keyframes eBIictmx2RG4_tr__tr {
  0% {
    transform: translate(180.107px, 179.927px) rotate(0deg);
    animation-timing-function: cubic-bezier(0.53, 0, 0.47, 1);
  }
  54.433333% {
    transform: translate(180.107px, 179.927px) rotate(360deg);
  }
  100% {
    transform: translate(180.107px, 179.927px) rotate(360deg);
  }
}
#eBIictmx2RG6_ts {
  animation: eBIictmx2RG6_ts__ts 3000ms linear infinite normal forwards;
}
@keyframes eBIictmx2RG6_ts__ts {
  0% {
    transform: translate(36.1px, 98.125px) scale(0, 0);
  }
  12.233333% {
    transform: translate(36.1px, 98.125px) scale(0, 0);
    animation-timing-function: cubic-bezier(0.27, 0, 0.32, 1);
  }
  28.9% {
    transform: translate(36.1px, 98.125px) scale(1, 1);
    animation-timing-function: cubic-bezier(0.68, 0, 0.73, 1);
  }
  46.666667% {
    transform: translate(36.1px, 98.125px) scale(0, 0);
  }
  100% {
    transform: translate(36.1px, 98.125px) scale(0, 0);
  }
}
#eBIictmx2RG8_tr {
  animation: eBIictmx2RG8_tr__tr 3000ms linear infinite normal forwards;
}
@keyframes eBIictmx2RG8_tr__tr {
  0% {
    transform: translate(180.107px, 179.927px) rotate(0deg);
    animation-timing-function: cubic-bezier(0.53, 0, 0.47, 1);
  }
  54.433333% {
    transform: translate(180.107px, 179.927px) rotate(360deg);
  }
  100% {
    transform: translate(180.107px, 179.927px) rotate(360deg);
  }
}
#eBIictmx2RG10_ts {
  animation: eBIictmx2RG10_ts__ts 3000ms linear infinite normal forwards;
}
@keyframes eBIictmx2RG10_ts__ts {
  0% {
    transform: translate(25.292px, 93.599px) scale(0, 0);
  }
  10% {
    transform: translate(25.292px, 93.599px) scale(0, 0);
    animation-timing-function: cubic-bezier(0.27, 0, 0.32, 1);
  }
  26.666667% {
    transform: translate(25.292px, 93.599px) scale(1, 1);
    animation-timing-function: cubic-bezier(0.68, 0, 0.73, 1);
  }
  44.433333% {
    transform: translate(25.292px, 93.599px) scale(0, 0);
  }
  100% {
    transform: translate(25.292px, 93.599px) scale(0, 0);
  }
}
#eBIictmx2RG12_tr {
  animation: eBIictmx2RG12_tr__tr 3000ms linear infinite normal forwards;
}
@keyframes eBIictmx2RG12_tr__tr {
  0% {
    transform: translate(180.107px, 179.927px) rotate(0deg);
    animation-timing-function: cubic-bezier(0.53, 0, 0.47, 1);
  }
  54.433333% {
    transform: translate(180.107px, 179.927px) rotate(360deg);
  }
  100% {
    transform: translate(180.107px, 179.927px) rotate(360deg);
  }
}
#eBIictmx2RG14_ts {
  animation: eBIictmx2RG14_ts__ts 3000ms linear infinite normal forwards;
}
@keyframes eBIictmx2RG14_ts__ts {
  0% {
    transform: translate(16.064px, 86.556px) scale(0, 0);
  }
  7.766667% {
    transform: translate(16.064px, 86.556px) scale(0, 0);
    animation-timing-function: cubic-bezier(0.27, 0, 0.32, 1);
  }
  24.433333% {
    transform: translate(16.064px, 86.556px) scale(1, 1);
    animation-timing-function: cubic-bezier(0.68, 0, 0.73, 1);
  }
  42.233333% {
    transform: translate(16.064px, 86.556px) scale(0, 0);
  }
  100% {
    transform: translate(16.064px, 86.556px) scale(0, 0);
  }
}
#eBIictmx2RG16_tr {
  animation: eBIictmx2RG16_tr__tr 3000ms linear infinite normal forwards;
}
@keyframes eBIictmx2RG16_tr__tr {
  0% {
    transform: translate(180.107px, 179.927px) rotate(0deg);
    animation-timing-function: cubic-bezier(0.53, 0, 0.47, 1);
  }
  54.433333% {
    transform: translate(180.107px, 179.927px) rotate(360deg);
  }
  100% {
    transform: translate(180.107px, 179.927px) rotate(360deg);
  }
}
#eBIictmx2RG18_ts {
  animation: eBIictmx2RG18_ts__ts 3000ms linear infinite normal forwards;
}
@keyframes eBIictmx2RG18_ts__ts {
  0% {
    transform: translate(8.957px, 77.247px) scale(0, 0);
  }
  5.566667% {
    transform: translate(8.957px, 77.247px) scale(0, 0);
    animation-timing-function: cubic-bezier(0.27, 0, 0.32, 1);
  }
  22.233333% {
    transform: translate(8.957px, 77.247px) scale(1, 1);
    animation-timing-function: cubic-bezier(0.68, 0, 0.73, 1);
  }
  40% {
    transform: translate(8.957px, 77.247px) scale(0, 0);
  }
  100% {
    transform: translate(8.957px, 77.247px) scale(0, 0);
  }
}
#eBIictmx2RG20_tr {
  animation: eBIictmx2RG20_tr__tr 3000ms linear infinite normal forwards;
}
@keyframes eBIictmx2RG20_tr__tr {
  0% {
    transform: translate(180.107px, 179.927px) rotate(0deg);
    animation-timing-function: cubic-bezier(0.53, 0, 0.47, 1);
  }
  54.433333% {
    transform: translate(180.107px, 179.927px) rotate(360deg);
  }
  100% {
    transform: translate(180.107px, 179.927px) rotate(360deg);
  }
}
#eBIictmx2RG22_ts {
  animation: eBIictmx2RG22_ts__ts 3000ms linear infinite normal forwards;
}
@keyframes eBIictmx2RG22_ts__ts {
  0% {
    transform: translate(4.486px, 66.522px) scale(0, 0);
  }
  3.333333% {
    transform: translate(4.486px, 66.522px) scale(0, 0);
    animation-timing-function: cubic-bezier(0.27, 0, 0.32, 1);
  }
  20% {
    transform: translate(4.486px, 66.522px) scale(1, 1);
    animation-timing-function: cubic-bezier(0.68, 0, 0.73, 1);
  }
  37.766667% {
    transform: translate(4.486px, 66.522px) scale(0, 0);
  }
  100% {
    transform: translate(4.486px, 66.522px) scale(0, 0);
  }
}
#eBIictmx2RG24_tr {
  animation: eBIictmx2RG24_tr__tr 3000ms linear infinite normal forwards;
}
@keyframes eBIictmx2RG24_tr__tr {
  0% {
    transform: translate(180.107px, 179.927px) rotate(0deg);
    animation-timing-function: cubic-bezier(0.53, 0, 0.47, 1);
  }
  54.433333% {
    transform: translate(180.107px, 179.927px) rotate(360deg);
  }
  100% {
    transform: translate(180.107px, 179.927px) rotate(360deg);
  }
}
#eBIictmx2RG27_tr {
  animation: eBIictmx2RG27_tr__tr 3000ms linear infinite normal forwards;
}
@keyframes eBIictmx2RG27_tr__tr {
  0% {
    transform: translate(180.107px, 179.927px) rotate(0deg);
    animation-timing-function: cubic-bezier(0.53, 0, 0.47, 1);
  }
  54.433333% {
    transform: translate(180.107px, 179.927px) rotate(360deg);
  }
  100% {
    transform: translate(180.107px, 179.927px) rotate(360deg);
  }
}
#eBIictmx2RG29_ts {
  animation: eBIictmx2RG29_ts__ts 3000ms linear infinite normal forwards;
}
@keyframes eBIictmx2RG29_ts__ts {
  0% {
    transform: translate(90.855px, 43.342px) scale(0, 0);
  }
  3.333333% {
    transform: translate(90.855px, 43.342px) scale(0, 0);
    animation-timing-function: cubic-bezier(0.27, 0, 0.32, 1);
  }
  20% {
    transform: translate(90.855px, 43.342px) scale(1, 1);
    animation-timing-function: cubic-bezier(0.68, 0, 0.73, 1);
  }
  37.766667% {
    transform: translate(90.855px, 43.342px) scale(0, 0);
  }
  100% {
    transform: translate(90.855px, 43.342px) scale(0, 0);
  }
}
#eBIictmx2RG31_tr {
  animation: eBIictmx2RG31_tr__tr 3000ms linear infinite normal forwards;
}
@keyframes eBIictmx2RG31_tr__tr {
  0% {
    transform: translate(180.107px, 179.927px) rotate(0deg);
    animation-timing-function: cubic-bezier(0.53, 0, 0.47, 1);
  }
  54.433333% {
    transform: translate(180.107px, 179.927px) rotate(360deg);
  }
  100% {
    transform: translate(180.107px, 179.927px) rotate(360deg);
  }
}
#eBIictmx2RG33_ts {
  animation: eBIictmx2RG33_ts__ts 3000ms linear infinite normal forwards;
}
@keyframes eBIictmx2RG33_ts__ts {
  0% {
    transform: translate(86.385px, 32.526px) scale(0, 0);
  }
  5.566667% {
    transform: translate(86.385px, 32.526px) scale(0, 0);
    animation-timing-function: cubic-bezier(0.27, 0, 0.32, 1);
  }
  22.233333% {
    transform: translate(86.385px, 32.526px) scale(1, 1);
    animation-timing-function: cubic-bezier(0.68, 0, 0.73, 1);
  }
  40% {
    transform: translate(86.385px, 32.526px) scale(0, 0);
  }
  100% {
    transform: translate(86.385px, 32.526px) scale(0, 0);
  }
}
#eBIictmx2RG35_tr {
  animation: eBIictmx2RG35_tr__tr 3000ms linear infinite normal forwards;
}
@keyframes eBIictmx2RG35_tr__tr {
  0% {
    transform: translate(180.107px, 179.927px) rotate(0deg);
    animation-timing-function: cubic-bezier(0.53, 0, 0.47, 1);
  }
  54.433333% {
    transform: translate(180.107px, 179.927px) rotate(360deg);
  }
  100% {
    transform: translate(180.107px, 179.927px) rotate(360deg);
  }
}
#eBIictmx2RG37_ts {
  animation: eBIictmx2RG37_ts__ts 3000ms linear infinite normal forwards;
}
@keyframes eBIictmx2RG37_ts__ts {
  0% {
    transform: translate(79.278px, 23.336px) scale(0, 0);
  }
  7.766667% {
    transform: translate(79.278px, 23.336px) scale(0, 0);
    animation-timing-function: cubic-bezier(0.27, 0, 0.32, 1);
  }
  24.433333% {
    transform: translate(79.278px, 23.336px) scale(1, 1);
    animation-timing-function: cubic-bezier(0.68, 0, 0.73, 1);
  }
  42.233333% {
    transform: translate(79.278px, 23.336px) scale(0, 0);
  }
  100% {
    transform: translate(79.278px, 23.336px) scale(0, 0);
  }
}
#eBIictmx2RG39_tr {
  animation: eBIictmx2RG39_tr__tr 3000ms linear infinite normal forwards;
}
@keyframes eBIictmx2RG39_tr__tr {
  0% {
    transform: translate(180.107px, 179.927px) rotate(0deg);
    animation-timing-function: cubic-bezier(0.53, 0, 0.47, 1);
  }
  54.433333% {
    transform: translate(180.107px, 179.927px) rotate(360deg);
  }
  100% {
    transform: translate(180.107px, 179.927px) rotate(360deg);
  }
}
#eBIictmx2RG41_ts {
  animation: eBIictmx2RG41_ts__ts 3000ms linear infinite normal forwards;
}
@keyframes eBIictmx2RG41_ts__ts {
  0% {
    transform: translate(70.051px, 16.17px) scale(0, 0);
  }
  10% {
    transform: translate(70.051px, 16.17px) scale(0, 0);
    animation-timing-function: cubic-bezier(0.27, 0, 0.32, 1);
  }
  26.666667% {
    transform: translate(70.051px, 16.17px) scale(1, 1);
    animation-timing-function: cubic-bezier(0.68, 0, 0.73, 1);
  }
  44.433333% {
    transform: translate(70.051px, 16.17px) scale(0, 0);
  }
  100% {
    transform: translate(70.051px, 16.17px) scale(0, 0);
  }
}
#eBIictmx2RG43_tr {
  animation: eBIictmx2RG43_tr__tr 3000ms linear infinite normal forwards;
}
@keyframes eBIictmx2RG43_tr__tr {
  0% {
    transform: translate(180.107px, 179.927px) rotate(0deg);
    animation-timing-function: cubic-bezier(0.53, 0, 0.47, 1);
  }
  54.433333% {
    transform: translate(180.107px, 179.927px) rotate(360deg);
  }
  100% {
    transform: translate(180.107px, 179.927px) rotate(360deg);
  }
}
#eBIictmx2RG45_ts {
  animation: eBIictmx2RG45_ts__ts 3000ms linear infinite normal forwards;
}
@keyframes eBIictmx2RG45_ts__ts {
  0% {
    transform: translate(59.242px, 11.743px) scale(0, 0);
  }
  12.233333% {
    transform: translate(59.242px, 11.743px) scale(0, 0);
    animation-timing-function: cubic-bezier(0.27, 0, 0.32, 1);
  }
  28.9% {
    transform: translate(59.242px, 11.743px) scale(1, 1);
    animation-timing-function: cubic-bezier(0.68, 0, 0.73, 1);
  }
  46.666667% {
    transform: translate(59.242px, 11.743px) scale(0, 0);
  }
  100% {
    transform: translate(59.242px, 11.743px) scale(0, 0);
  }
}
#eBIictmx2RG47_tr {
  animation: eBIictmx2RG47_tr__tr 3000ms linear infinite normal forwards;
}
@keyframes eBIictmx2RG47_tr__tr {
  0% {
    transform: translate(180.107px, 179.927px) rotate(0deg);
    animation-timing-function: cubic-bezier(0.53, 0, 0.47, 1);
  }
  54.433333% {
    transform: translate(180.107px, 179.927px) rotate(360deg);
  }
  100% {
    transform: translate(180.107px, 179.927px) rotate(360deg);
  }
}

#epQFcirRlVE4_tr {
  animation: epQFcirRlVE4_tr__tr 3000ms linear infinite normal forwards;
}
@keyframes epQFcirRlVE4_tr__tr {
  0% {
    transform: translate(180.107px, 179.927px) rotate(0deg);
    animation-timing-function: cubic-bezier(0.53, 0, 0.47, 1);
  }
  54.433333% {
    transform: translate(180.107px, 179.927px) rotate(360deg);
  }
  100% {
    transform: translate(180.107px, 179.927px) rotate(360deg);
  }
}
#epQFcirRlVE6_ts {
  animation: epQFcirRlVE6_ts__ts 3000ms linear infinite normal forwards;
}
@keyframes epQFcirRlVE6_ts__ts {
  0% {
    transform: translate(36.1px, 98.125px) scale(0, 0);
  }
  12.233333% {
    transform: translate(36.1px, 98.125px) scale(0, 0);
    animation-timing-function: cubic-bezier(0.27, 0, 0.32, 1);
  }
  28.9% {
    transform: translate(36.1px, 98.125px) scale(1, 1);
    animation-timing-function: cubic-bezier(0.68, 0, 0.73, 1);
  }
  46.666667% {
    transform: translate(36.1px, 98.125px) scale(0, 0);
  }
  100% {
    transform: translate(36.1px, 98.125px) scale(0, 0);
  }
}
#epQFcirRlVE8_tr {
  animation: epQFcirRlVE8_tr__tr 3000ms linear infinite normal forwards;
}
@keyframes epQFcirRlVE8_tr__tr {
  0% {
    transform: translate(180.107px, 179.927px) rotate(0deg);
    animation-timing-function: cubic-bezier(0.53, 0, 0.47, 1);
  }
  54.433333% {
    transform: translate(180.107px, 179.927px) rotate(360deg);
  }
  100% {
    transform: translate(180.107px, 179.927px) rotate(360deg);
  }
}
#epQFcirRlVE10_ts {
  animation: epQFcirRlVE10_ts__ts 3000ms linear infinite normal forwards;
}
@keyframes epQFcirRlVE10_ts__ts {
  0% {
    transform: translate(25.292px, 93.599px) scale(0, 0);
  }
  10% {
    transform: translate(25.292px, 93.599px) scale(0, 0);
    animation-timing-function: cubic-bezier(0.27, 0, 0.32, 1);
  }
  26.666667% {
    transform: translate(25.292px, 93.599px) scale(1, 1);
    animation-timing-function: cubic-bezier(0.68, 0, 0.73, 1);
  }
  44.433333% {
    transform: translate(25.292px, 93.599px) scale(0, 0);
  }
  100% {
    transform: translate(25.292px, 93.599px) scale(0, 0);
  }
}
#epQFcirRlVE12_tr {
  animation: epQFcirRlVE12_tr__tr 3000ms linear infinite normal forwards;
}
@keyframes epQFcirRlVE12_tr__tr {
  0% {
    transform: translate(180.107px, 179.927px) rotate(0deg);
    animation-timing-function: cubic-bezier(0.53, 0, 0.47, 1);
  }
  54.433333% {
    transform: translate(180.107px, 179.927px) rotate(360deg);
  }
  100% {
    transform: translate(180.107px, 179.927px) rotate(360deg);
  }
}
#epQFcirRlVE14_ts {
  animation: epQFcirRlVE14_ts__ts 3000ms linear infinite normal forwards;
}
@keyframes epQFcirRlVE14_ts__ts {
  0% {
    transform: translate(16.064px, 86.556px) scale(0, 0);
  }
  7.766667% {
    transform: translate(16.064px, 86.556px) scale(0, 0);
    animation-timing-function: cubic-bezier(0.27, 0, 0.32, 1);
  }
  24.433333% {
    transform: translate(16.064px, 86.556px) scale(1, 1);
    animation-timing-function: cubic-bezier(0.68, 0, 0.73, 1);
  }
  42.233333% {
    transform: translate(16.064px, 86.556px) scale(0, 0);
  }
  100% {
    transform: translate(16.064px, 86.556px) scale(0, 0);
  }
}
#epQFcirRlVE16_tr {
  animation: epQFcirRlVE16_tr__tr 3000ms linear infinite normal forwards;
}
@keyframes epQFcirRlVE16_tr__tr {
  0% {
    transform: translate(180.107px, 179.927px) rotate(0deg);
    animation-timing-function: cubic-bezier(0.53, 0, 0.47, 1);
  }
  54.433333% {
    transform: translate(180.107px, 179.927px) rotate(360deg);
  }
  100% {
    transform: translate(180.107px, 179.927px) rotate(360deg);
  }
}
#epQFcirRlVE18_ts {
  animation: epQFcirRlVE18_ts__ts 3000ms linear infinite normal forwards;
}
@keyframes epQFcirRlVE18_ts__ts {
  0% {
    transform: translate(8.957px, 77.247px) scale(0, 0);
  }
  5.566667% {
    transform: translate(8.957px, 77.247px) scale(0, 0);
    animation-timing-function: cubic-bezier(0.27, 0, 0.32, 1);
  }
  22.233333% {
    transform: translate(8.957px, 77.247px) scale(1, 1);
    animation-timing-function: cubic-bezier(0.68, 0, 0.73, 1);
  }
  40% {
    transform: translate(8.957px, 77.247px) scale(0, 0);
  }
  100% {
    transform: translate(8.957px, 77.247px) scale(0, 0);
  }
}
#epQFcirRlVE20_tr {
  animation: epQFcirRlVE20_tr__tr 3000ms linear infinite normal forwards;
}
@keyframes epQFcirRlVE20_tr__tr {
  0% {
    transform: translate(180.107px, 179.927px) rotate(0deg);
    animation-timing-function: cubic-bezier(0.53, 0, 0.47, 1);
  }
  54.433333% {
    transform: translate(180.107px, 179.927px) rotate(360deg);
  }
  100% {
    transform: translate(180.107px, 179.927px) rotate(360deg);
  }
}
#epQFcirRlVE22_ts {
  animation: epQFcirRlVE22_ts__ts 3000ms linear infinite normal forwards;
}
@keyframes epQFcirRlVE22_ts__ts {
  0% {
    transform: translate(4.486px, 66.522px) scale(0, 0);
  }
  3.333333% {
    transform: translate(4.486px, 66.522px) scale(0, 0);
    animation-timing-function: cubic-bezier(0.27, 0, 0.32, 1);
  }
  20% {
    transform: translate(4.486px, 66.522px) scale(1, 1);
    animation-timing-function: cubic-bezier(0.68, 0, 0.73, 1);
  }
  37.766667% {
    transform: translate(4.486px, 66.522px) scale(0, 0);
  }
  100% {
    transform: translate(4.486px, 66.522px) scale(0, 0);
  }
}
#epQFcirRlVE24_tr {
  animation: epQFcirRlVE24_tr__tr 3000ms linear infinite normal forwards;
}
@keyframes epQFcirRlVE24_tr__tr {
  0% {
    transform: translate(180.107px, 179.927px) rotate(0deg);
    animation-timing-function: cubic-bezier(0.53, 0, 0.47, 1);
  }
  54.433333% {
    transform: translate(180.107px, 179.927px) rotate(360deg);
  }
  100% {
    transform: translate(180.107px, 179.927px) rotate(360deg);
  }
}
#epQFcirRlVE27_tr {
  animation: epQFcirRlVE27_tr__tr 3000ms linear infinite normal forwards;
}
@keyframes epQFcirRlVE27_tr__tr {
  0% {
    transform: translate(180.107px, 179.927px) rotate(0deg);
    animation-timing-function: cubic-bezier(0.53, 0, 0.47, 1);
  }
  54.433333% {
    transform: translate(180.107px, 179.927px) rotate(360deg);
  }
  100% {
    transform: translate(180.107px, 179.927px) rotate(360deg);
  }
}
#epQFcirRlVE29_ts {
  animation: epQFcirRlVE29_ts__ts 3000ms linear infinite normal forwards;
}
@keyframes epQFcirRlVE29_ts__ts {
  0% {
    transform: translate(90.855px, 43.342px) scale(0, 0);
  }
  3.333333% {
    transform: translate(90.855px, 43.342px) scale(0, 0);
    animation-timing-function: cubic-bezier(0.27, 0, 0.32, 1);
  }
  20% {
    transform: translate(90.855px, 43.342px) scale(1, 1);
    animation-timing-function: cubic-bezier(0.68, 0, 0.73, 1);
  }
  37.766667% {
    transform: translate(90.855px, 43.342px) scale(0, 0);
  }
  100% {
    transform: translate(90.855px, 43.342px) scale(0, 0);
  }
}
#epQFcirRlVE31_tr {
  animation: epQFcirRlVE31_tr__tr 3000ms linear infinite normal forwards;
}
@keyframes epQFcirRlVE31_tr__tr {
  0% {
    transform: translate(180.107px, 179.927px) rotate(0deg);
    animation-timing-function: cubic-bezier(0.53, 0, 0.47, 1);
  }
  54.433333% {
    transform: translate(180.107px, 179.927px) rotate(360deg);
  }
  100% {
    transform: translate(180.107px, 179.927px) rotate(360deg);
  }
}
#epQFcirRlVE33_ts {
  animation: epQFcirRlVE33_ts__ts 3000ms linear infinite normal forwards;
}
@keyframes epQFcirRlVE33_ts__ts {
  0% {
    transform: translate(86.385px, 32.526px) scale(0, 0);
  }
  5.566667% {
    transform: translate(86.385px, 32.526px) scale(0, 0);
    animation-timing-function: cubic-bezier(0.27, 0, 0.32, 1);
  }
  22.233333% {
    transform: translate(86.385px, 32.526px) scale(1, 1);
    animation-timing-function: cubic-bezier(0.68, 0, 0.73, 1);
  }
  40% {
    transform: translate(86.385px, 32.526px) scale(0, 0);
  }
  100% {
    transform: translate(86.385px, 32.526px) scale(0, 0);
  }
}
#epQFcirRlVE35_tr {
  animation: epQFcirRlVE35_tr__tr 3000ms linear infinite normal forwards;
}
@keyframes epQFcirRlVE35_tr__tr {
  0% {
    transform: translate(180.107px, 179.927px) rotate(0deg);
    animation-timing-function: cubic-bezier(0.53, 0, 0.47, 1);
  }
  54.433333% {
    transform: translate(180.107px, 179.927px) rotate(360deg);
  }
  100% {
    transform: translate(180.107px, 179.927px) rotate(360deg);
  }
}
#epQFcirRlVE37_ts {
  animation: epQFcirRlVE37_ts__ts 3000ms linear infinite normal forwards;
}
@keyframes epQFcirRlVE37_ts__ts {
  0% {
    transform: translate(79.278px, 23.336px) scale(0, 0);
  }
  7.766667% {
    transform: translate(79.278px, 23.336px) scale(0, 0);
    animation-timing-function: cubic-bezier(0.27, 0, 0.32, 1);
  }
  24.433333% {
    transform: translate(79.278px, 23.336px) scale(1, 1);
    animation-timing-function: cubic-bezier(0.68, 0, 0.73, 1);
  }
  42.233333% {
    transform: translate(79.278px, 23.336px) scale(0, 0);
  }
  100% {
    transform: translate(79.278px, 23.336px) scale(0, 0);
  }
}
#epQFcirRlVE39_tr {
  animation: epQFcirRlVE39_tr__tr 3000ms linear infinite normal forwards;
}
@keyframes epQFcirRlVE39_tr__tr {
  0% {
    transform: translate(180.107px, 179.927px) rotate(0deg);
    animation-timing-function: cubic-bezier(0.53, 0, 0.47, 1);
  }
  54.433333% {
    transform: translate(180.107px, 179.927px) rotate(360deg);
  }
  100% {
    transform: translate(180.107px, 179.927px) rotate(360deg);
  }
}
#epQFcirRlVE41_ts {
  animation: epQFcirRlVE41_ts__ts 3000ms linear infinite normal forwards;
}
@keyframes epQFcirRlVE41_ts__ts {
  0% {
    transform: translate(70.051px, 16.17px) scale(0, 0);
  }
  10% {
    transform: translate(70.051px, 16.17px) scale(0, 0);
    animation-timing-function: cubic-bezier(0.27, 0, 0.32, 1);
  }
  26.666667% {
    transform: translate(70.051px, 16.17px) scale(1, 1);
    animation-timing-function: cubic-bezier(0.68, 0, 0.73, 1);
  }
  44.433333% {
    transform: translate(70.051px, 16.17px) scale(0, 0);
  }
  100% {
    transform: translate(70.051px, 16.17px) scale(0, 0);
  }
}
#epQFcirRlVE43_tr {
  animation: epQFcirRlVE43_tr__tr 3000ms linear infinite normal forwards;
}
@keyframes epQFcirRlVE43_tr__tr {
  0% {
    transform: translate(180.107px, 179.927px) rotate(0deg);
    animation-timing-function: cubic-bezier(0.53, 0, 0.47, 1);
  }
  54.433333% {
    transform: translate(180.107px, 179.927px) rotate(360deg);
  }
  100% {
    transform: translate(180.107px, 179.927px) rotate(360deg);
  }
}
#epQFcirRlVE45_ts {
  animation: epQFcirRlVE45_ts__ts 3000ms linear infinite normal forwards;
}
@keyframes epQFcirRlVE45_ts__ts {
  0% {
    transform: translate(59.242px, 11.743px) scale(0, 0);
  }
  12.233333% {
    transform: translate(59.242px, 11.743px) scale(0, 0);
    animation-timing-function: cubic-bezier(0.27, 0, 0.32, 1);
  }
  28.9% {
    transform: translate(59.242px, 11.743px) scale(1, 1);
    animation-timing-function: cubic-bezier(0.68, 0, 0.73, 1);
  }
  46.666667% {
    transform: translate(59.242px, 11.743px) scale(0, 0);
  }
  100% {
    transform: translate(59.242px, 11.743px) scale(0, 0);
  }
}
#epQFcirRlVE47_tr {
  animation: epQFcirRlVE47_tr__tr 3000ms linear infinite normal forwards;
}
@keyframes epQFcirRlVE47_tr__tr {
  0% {
    transform: translate(180.107px, 179.927px) rotate(0deg);
    animation-timing-function: cubic-bezier(0.53, 0, 0.47, 1);
  }
  54.433333% {
    transform: translate(180.107px, 179.927px) rotate(360deg);
  }
  100% {
    transform: translate(180.107px, 179.927px) rotate(360deg);
  }
}
</style>
