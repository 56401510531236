<template>
  <div>
    <VueMultiselect
      v-model="value"
      label="label"
      track-by="value"
      :options="options"
      :searchable="false"
      :clear-on-select="false"
      :placeholder="placeholder"
    >
    </VueMultiselect>
  </div>
</template>
  
<script>
import VueMultiselect from "vue-multiselect";
export default {
  components: { VueMultiselect },
  props: {
    modelValue: {
      type: Object,
    },
    placeholder: {
      type: String,
      default: "Выберите ...",
    },
    options: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    value: {
      get() {
        return this.modelValue;
      },
      set(val) {
        this.$emit("update:model-value", val);
      },
    },
  },
};
</script>

<style lang="scss">
@import "vue-multiselect/dist/vue-multiselect.css";

.multiselect {
  &__tags {
    background: #ffffff;
    box-shadow: 0px 5px 15px #e9e6fb;
    border-radius: 4px;
    height: 45px;
    width: 100%;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.05em;
    color: #000000;
    border: none;
    padding: 0;

    display: flex;
    align-items: center;

    padding-left: 16px;
  }

  &__placeholder,
  &__single,
  &__input {
    margin: 0;
    padding: 0;
  }

  &__select {
    height: 100%;
  }
  &__content-wrapper {
    border: none;
  }
}

.theme__dark {
  .multiselect {
    &__tags {
      background: rgba(120, 129, 157, 0.15);
    }
    &__content-wrapper {
      background: #303541;
      color: #ffffff;
    }
    &__single {
      background: transparent;
      color: #ffffff;
    }
  }
}
</style>